import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import DOMPurify from 'dompurify';

// Configure DOMPurify for safe HTML
const purifyConfig = {
  ALLOWED_TAGS: ['p', 'br', 'b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'code', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'span'],
  ALLOWED_ATTR: ['href', 'target', 'class', 'data-emoji'],
  USE_PROFILES: { html: true },
  KEEP_CONTENT: true,
  ALLOW_DATA_ATTR: true,
  ADD_URI_SAFE_ATTR: ['emoji-data'] // Safe attribute for emoji
};

// Function to safely sanitize HTML content while preserving emojis
function sanitizeHTML(html) {
  if (!html || html.trim() === '') {
    return '';
  }
  
  if (html === '<div class="formatted-content"></div>') {
    return '';
  }
  
  if (!html || typeof html !== 'string') {
    console.warn('sanitizeHTML received invalid input:', html);
    return '';
  }
  
  // Simple approach: encode emojis with a marker before sanitization and decode after
  // Use unique markers unlikely to appear in normal text
  const markerPrefix = '__EMOJI_';
  const markerSuffix = '__';
  
  // Replace emojis with temporary markers
  const emojiMap = new Map();
  let markerCount = 0;
  
  // Enhanced regex to catch most emoji patterns including ZWJ sequences
  const emojiRegex = /(\p{Emoji}(\p{Emoji_Modifier}|\u200D\p{Emoji})*)/ug;
  
  try {
  const htmlWithMarkers = html.replace(emojiRegex, (match) => {
    const marker = `${markerPrefix}${markerCount}${markerSuffix}`;
    emojiMap.set(marker, match);
    markerCount++;
    return marker;
  });
  
  // Sanitize the HTML with markers instead of emojis
  const sanitized = DOMPurify.sanitize(htmlWithMarkers, purifyConfig);
  
  // Replace markers back with original emojis
  const finalHtml = sanitized.replace(new RegExp(`${markerPrefix}\\d+${markerSuffix}`, 'g'), (marker) => {
    return emojiMap.get(marker) || marker;
  });
  
  return finalHtml;
  } catch (error) {
    console.error('Error in sanitizeHTML:', error);
    return html; // Return original content if sanitization fails
  }
}

// Add this function after sanitizeHTML but before other functions
function transformCitations(html) {
  if (!html) return '';
  
  // Pattern matches OpenAI's citation format: ([domain.com](https://domain.com/path?utm_source=openai))
  const citationPattern = /\(\[(.*?)\]\((.*?)(?:\?utm_source=openai)?\)/g;
  
  // Replace with clean [SourceName] format
  return html.replace(citationPattern, (match, sourceName, url) => {
    // Remove utm parameters if present
    const cleanUrl = url.replace(/\?utm_source=openai.*$/, '');
    
    // Create a clean citation link
    return `<a href="${cleanUrl}" target="_blank" rel="noopener noreferrer" class="citation-link">[${sourceName}]</a>`;
  });
}

// Dynamically load the worker script at runtime from the public directory
const workerScriptPath = '/pdf.worker.min.js';

// Set up pdf.js worker using Blob URL
async function setupWorker() {
  try {
    const response = await fetch(workerScriptPath);
    if (!response.ok) throw new Error(`Failed to fetch worker script: ${response.statusText}`);
    const workerContent = await response.text();
    const workerBlob = new Blob([workerContent], { type: 'application/javascript' });
    const workerUrl = URL.createObjectURL(workerBlob);
    pdfjsLib.GlobalWorkerOptions.workerSrc = workerUrl;
    console.log('pdf.js worker loaded successfully');
    return workerUrl;
  } catch (err) {
    console.error('Failed to set up pdf.js worker:', err);
    throw err;
  }
}

// Retrieve token and userId from localStorage (set by getstarted.html)
let token = localStorage.getItem('token');
let userId = localStorage.getItem('userId');
const sessionId = Date.now().toString();

// If no token/userId, log and await sign-in
if (!userId || !token) {
  // Try to get token from cookie as fallback
  const cookieToken = getCookie('authToken');
  if (cookieToken) {
    console.log('Found auth token in cookie, attempting to verify');
    token = cookieToken;
    // We'll get userId after verification
  } else {
    console.log('No token or userId found, awaiting sign-in from getstarted.html');
  }
}

let totalCredits = 0;
let creditsUsed = 0;

// Add this after other global variables at the top
// Track modules that are currently being processed to prevent duplicates
if (!window.modulesInProgress) {
  window.modulesInProgress = new Set();
}

// Keep track of which modules have been processed in this session
if (!window.completedModuleCache) {
  window.completedModuleCache = new Set();
}

// Flag to indicate when batch processing is active
window.isBatchProcessing = false;

// Get page-specific tag from body data attribute (if any)
const pageTag = document.body.dataset.pageTag || null;

// Helper function to get cookies
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

// Add this function before the document ready event
async function checkResponsesApiEndpoint() {
  try {
    console.log("Checking if Responses API endpoint exists...");
    const response = await fetch('/ask-ai-responses', {
      method: 'HEAD',
      headers: { 'Authorization': `Bearer ${token || getCookie('token')}` }
    });
    
    // If we get a 404, the endpoint doesn't exist
    if (response.status === 404) {
      console.warn("Responses API endpoint does not exist. Disabling Responses API.");
      window.useResponsesApi = false;
      return false;
    }
    
    // Any other response means the endpoint exists (even if auth failed)
    console.log("Responses API endpoint exists. Status:", response.status);
    return true;
  } catch (error) {
    console.error("Error checking Responses API endpoint:", error);
    // Keep the default setting
    return true;
  }
}

$(document).ready(async function() {
  // Ensure we properly initialize useResponsesApi
  if (typeof window.useResponsesApi === 'undefined') {
    // Set this to true to use the Responses API
    window.useResponsesApi = true;
  }
  
  // Cancellation state management
  let isCancelling = false; // Track active cancellation
  let cancellationTime = null; // Timestamp when cancellation occurred
  
  // Add CSS rule to ensure hidden-input class works correctly
  const hiddenInputStyle = document.createElement('style');
  hiddenInputStyle.textContent = `
    .history-input-container.hidden-input { 
      display: none !important; 
    }
    
    /* Web citations styles */
    .citations-container {
      margin-top: 16px;
      padding: 12px 16px;
      border-top: 1px solid #e2e8f0;
      background-color: #f8fafc;
      border-radius: 0 0 6px 6px;
    }
    
    .citations-title {
      font-weight: 600;
      margin-bottom: 8px;
      font-size: 14px;
      color: #475569;
    }
    
    .citations-list {
      list-style-type: decimal;
      padding-left: 20px;
      margin: 0;
    }
    
    .citation-item {
      margin-bottom: 6px;
      font-size: 13px;
    }
    
    .citation-link {
      color: #3b82f6;
      text-decoration: none;
      word-break: break-all;
      font-weight: 500;
    }
    
    .citation-link:hover {
      text-decoration: underline;
    }
    
    /* Inline citation style */
    .formatted-content .citation-link {
      font-weight: 500;
      color: #3b82f6;
      text-decoration: none;
      white-space: nowrap;
    }
    
    .formatted-content .citation-link:hover {
      text-decoration: underline;
    }
    
    /* Copy button styles */
    .copy-icon {
      display: inline-block;
      cursor: pointer;
      margin-left: 5px;
      vertical-align: middle;
      color: #6c757d;
    }
    
    .copy-icon:hover {
      color: #0d6efd;
    }
  `;
  document.head.appendChild(hiddenInputStyle);
  
  // Function to create copy SVG icon
  function createCopyIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
      <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1"></path>
    </svg>`;
  }
  
  // Function to create check mark SVG icon (for success feedback)
  function createCheckIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
      stroke="#28a745" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M20 6L9 17l-5-5"></path>
    </svg>`;
  }
  
  // Copy to clipboard function
  function copyToClipboard(text) {
    if (!text) return;
    
    navigator.clipboard.writeText(text).then(() => {
      console.log('Content copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }
  
  // Add copy button to "Text Output" heading
  setTimeout(() => {
    const contentHeaders = document.querySelectorAll('.content-header');
    contentHeaders.forEach(header => {
      if (header.textContent.trim() === 'Text Output') {
        // Only add if no copy button exists yet
        if (!header.querySelector('.copy-icon')) {
          header.innerHTML = `Text Output <span id="copyOutputBtn" class="copy-icon" title="Copy to clipboard">${createCopyIcon()}</span>`;
        }
      }
    });
    
    // Add event listener for the output copy button
    document.addEventListener('click', function(e) {
      if (e.target.closest('#copyOutputBtn')) {
        const outputText = document.getElementById('ai-response').innerText;
        copyToClipboard(outputText);
        
        // Visual feedback
        const icon = e.target.closest('#copyOutputBtn');
        icon.innerHTML = createCheckIcon();
        
        setTimeout(() => {
          icon.innerHTML = createCopyIcon();
        }, 1000);
      }
      
      // Handle history item copy buttons
      if (e.target.closest('.copy-history-btn')) {
        const btn = e.target.closest('.copy-history-btn');
        const index = btn.getAttribute('data-id');
        const contentElem = document.getElementById(`history-content-${index}`);
        
        if (contentElem) {
          const historyOutputElem = contentElem.querySelector('.history-output .formatted-content');
          if (historyOutputElem) {
            copyToClipboard(historyOutputElem.innerText);
            
            // Visual feedback
            const originalHTML = btn.innerHTML;
            btn.innerHTML = createCheckIcon();
            
            setTimeout(() => {
              btn.innerHTML = originalHTML;
            }, 1000);
          }
        }
      }
    });
  }, 500);
  
  // Check if the Responses API endpoint exists
  await checkResponsesApiEndpoint();
  
  // Log the current state for debugging
  console.log("Document loaded. useResponsesApi flag is set to:", window.useResponsesApi, 
              "(type:", typeof window.useResponsesApi, ")");
  
  // Setup history toggle functionality
  const historyToggle = document.getElementById('history-toggle');
  const historyContent = document.getElementById('history-content');
  const historyChevron = document.getElementById('history-chevron');
  
  if (historyToggle && historyContent && historyChevron) {
    historyToggle.addEventListener('click', function() {
      historyContent.classList.toggle('hidden');
      historyChevron.classList.toggle('rotate-180');
    });
  }
  
  // Initialize editor once
  const editor = initializeEditor();

  const select = document.getElementById('assistant-select');
  const historyList = document.getElementById('history-list');
  const aiResponse = document.getElementById('ai-response');
  const aiButton = document.getElementById('ai-button');
  const fileInput = document.getElementById('file-upload');
  const removeFileButton = document.getElementById('remove-file');
  const fileNameDisplay = document.getElementById('file-name');
  const creditsUsedSpan = document.getElementById('credits-used');
  const creditsTotalSpan = document.getElementById('credits-total');
  const anonymizeButton = document.getElementById('anonymize-data');
  const anonymizeModal = document.getElementById('anonymize-modal');
  const anonymizeConfirmInput = document.getElementById('anonymize-confirm-input');
  const anonymizeCancelButton = document.getElementById('anonymize-cancel');
  const anonymizeConfirmButton = document.getElementById('anonymize-confirm');
  let assistants = [];
  let workerUrl;
  let isProcessingAllModules = false;
  let convertedFileText = '';
  let isCancelled = false;
  let currentAbortController = null;
  let globalAttemptCounter = 0; // Add global attempt counter

  // Check token validity on load
  async function checkToken() {
    // Check for token in localStorage or cookie
    if (!token) {
      const cookieToken = getCookie('authToken');
      if (cookieToken) {
        console.log('Found auth token in cookie, will use it');
        token = cookieToken;
      } else {
        console.log('No token available, showing logged-out state');
        return false;
      }
    }
    
    try {
      // Use retryFetch for better resilience
      const response = await retryFetch('/auth/verify', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      }, 3, 1000, 10000); // 3 retries, 1s base delay, 10s timeout
      
      if (!response.ok) {
        // Only log out for auth failures (401/403)
        if (response.status === 401 || response.status === 403) {
          console.log(`Token verification failed with status ${response.status}, clearing localStorage`);
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          token = null;
          userId = null;
          return false;
        } else {
          // For other errors, keep user logged in but log the issue
          console.warn(`Token verification had non-auth error: ${response.status}, keeping user logged in`);
          return true; // Keep user logged in despite verification error
        }
      }
      
      const data = await response.json();
      console.log('Token verified successfully:', data);
      
      // Update userId if it was missing
      if (!userId && data.userId) {
        userId = data.userId;
        localStorage.setItem('userId', userId);
      }
      
      return true;
    } catch (err) {
      console.error('Token verification failed:', err);
      // Keep user logged in despite network errors
      return true;
    }
  }

  function updateSubmitButtonState() {
    // Special handling for cancellation state
    if (isCancelling) {
      aiButton.disabled = true;
      aiButton.classList.add('bg-gray-400', 'opacity-50', 'cursor-not-allowed');
      aiButton.classList.remove('bg-[#e50047]', 'hover:bg-[#cc0040]', 'bg-red-600', 'hover:bg-red-700');
      return;
    }
    
    if (isProcessingAllModules || creditsUsed >= totalCredits || !token) {
      aiButton.disabled = true;
      aiButton.classList.add('bg-gray-400', 'opacity-50', 'cursor-not-allowed');
      aiButton.classList.remove('bg-[#e50047]', 'hover:bg-[#cc0040]');
    } else {
      aiButton.disabled = false;
      aiButton.classList.remove('bg-gray-400', 'opacity-50', 'cursor-not-allowed', 'bg-red-600', 'hover:bg-red-700');
      aiButton.classList.add('bg-[#e50047]', 'hover:bg-[#cc0040]');
      aiButton.textContent = 'Submit';
      aiButton.setAttribute('data-action', 'submit');
    }
    console.log('Button state updated, disabled:', aiButton.disabled);
  }

  async function fetchCredits() {
    if (!token) {
      console.error('No token available for /credits');
      aiResponse.innerText = 'Please sign in to fetch credits';
      return;
    }
    try {
      const response = await fetch('/credits', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) {
        // Only display error message if not a 404 (payment system not available)
        if (response.status !== 404) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(`${errorData.error || 'Failed to fetch credits'} (Status: ${response.status})`);
        } else {
          // Just log the error but don't display an error message to the user
          console.warn('Credits service not available');
          return;
        }
      }
      const data = await response.json();
      if (data.totalCredits === undefined || data.usedCredits === undefined) {
        throw new Error('Invalid credits data: Missing totalCredits or usedCredits');
      }
      totalCredits = data.totalCredits;
      creditsUsed = data.usedCredits;
      creditsTotalSpan.textContent = totalCredits;
      creditsUsedSpan.textContent = creditsUsed;
      updateSubmitButtonState();
    } catch (err) {
      console.error('Failed to fetch credits:', err.message, { userId, url: '/credits', stack: err.stack });
      creditsTotalSpan.textContent = totalCredits || '∞';
      creditsUsedSpan.textContent = creditsUsed || '0';
      // Don't show error to user - just fail silently and use default values
    }
  }

  // Initial token check and fetch credits
  checkToken().then(valid => {
    if (valid) {
      fetchCredits();
    } else {
      // Don't immediately show error or redirect, give a chance for the session to establish
      console.log('Initial auth check failed, setting 3 second grace period');
      
      // Set a short timeout to allow other auth mechanisms to work
      setTimeout(() => {
        // Check again before showing error
        checkToken().then(retryValid => {
          if (retryValid) {
            fetchCredits();
          } else {
            // Only now show error and redirect
            aiResponse.innerText = 'Session expired or invalid. Please sign in again.';
            if (window.location.pathname !== '/getstarted.html') {
              console.log('Redirecting to login page after verification failures');
              setTimeout(() => window.location.href = '/getstarted.html', 1500);
            }
          }
        });
      }, 3000); // 3 second grace period
    }
  });

  setupWorker().then(url => {
    workerUrl = url;
  }).catch(err => {
    aiResponse.innerText = 'Error: Failed to initialize PDF processing';
    console.error('Worker setup failed:', err);
  });

  fileInput.addEventListener('change', () => {
    if (fileInput.files.length > 0) {
      removeFileButton.classList.remove('hidden');
      fileNameDisplay.textContent = fileInput.files[0].name;
      fileNameDisplay.classList.remove('italic', 'text-gray-600');
      fileNameDisplay.classList.add('font-semibold', 'text-black');
    } else {
      removeFileButton.classList.add('hidden');
      fileNameDisplay.textContent = 'No file chosen';
      fileNameDisplay.classList.add('italic', 'text-gray-600');
      fileNameDisplay.classList.remove('font-semibold', 'text-black');
    }
  });

  removeFileButton.addEventListener('click', () => {
    fileInput.value = '';
    removeFileButton.classList.add('hidden');
    fileNameDisplay.textContent = 'No file chosen';
    fileNameDisplay.classList.add('italic', 'text-gray-600');
    fileNameDisplay.classList.remove('font-semibold', 'text-black');
    convertedFileText = '';
  });

  anonymizeButton.addEventListener('click', () => {
    anonymizeModal.classList.remove('hidden');
    anonymizeConfirmInput.value = '';
    anonymizeConfirmButton.disabled = true;
  });

  anonymizeCancelButton.addEventListener('click', () => {
    anonymizeModal.classList.add('hidden');
  });

  anonymizeConfirmInput.addEventListener('input', () => {
    const inputValue = anonymizeConfirmInput.value.trim().toLowerCase();
    anonymizeConfirmButton.disabled = inputValue !== 'delete';
  });

  anonymizeConfirmButton.addEventListener('click', async () => {
    if (!token) {
      aiResponse.innerText = 'Error: Please sign in to anonymize data';
      anonymizeModal.classList.add('hidden');
      window.location.href = '/getstarted.html';
      return;
    }
    try {
      const response = await fetch('/anonymize', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
      });
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`${errorData.error || 'Failed to anonymize data'} (Status: ${response.status})`);
      }
      const result = await response.json();
      
      // Use exactly the same structure as the blue processing message
      const normalContainer = document.getElementById('normal-response');
      normalContainer.innerHTML = `
        <div class="mb-4 relative overflow-hidden">
          <div class="bg-gray-900 text-white font-semibold p-3 rounded-lg relative overflow-hidden text-center">
            All Data Deleted
            <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
          </div>
        </div>
      `;
      
      // Add the shine effect CSS if it doesn't exist - exactly like in setLoading function
      if (!document.getElementById('shine-effect-style')) {
        const style = document.createElement('style');
        style.id = 'shine-effect-style';
        style.textContent = `
          @keyframes shine {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }
          .shine-effect {
            background: linear-gradient(
              90deg, 
              rgba(255,255,255,0) 0%, 
              rgba(255,255,255,0.6) 50%, 
              rgba(255,255,255,0) 100%
            );
            animation: shine 2s infinite linear;
            pointer-events: none;
          }
        `;
        document.head.appendChild(style);
      }
      
      // Show normal container, hide streaming container
      document.getElementById('streaming-response').classList.add('hidden');
      normalContainer.classList.remove('hidden');
      
      anonymizeModal.classList.add('hidden');
      await fetchCredits();
      loadHistory();
      reloadModuleIconStates(); // Reload module icon states after data deletion
    } catch (err) {
      console.error('Anonymization error:', err);
      aiResponse.innerText = `Error: ${err.message}`;
      anonymizeModal.classList.add('hidden');
    }
  });

  // Helper function to check if a response is the placeholder value
  function isPlaceholder(content) {
    return content && content.trim() === '*****';
  }

  // Build the URL with tag filter if page tag is present
  const assistantsUrl = pageTag ? `/assistants?tags=${pageTag}` : '/assistants';
  
  fetch(assistantsUrl)
    .then(res => {
      if (!res.ok) throw new Error(`Failed to fetch assistants: ${res.statusText}`);
      return res.json();
    })
    .then(data => {
      if (!Array.isArray(data) || data.some(assistant => !assistant.vanityName)) {
        throw new Error('Invalid assistants data');
      }
      
      // Sort assistants properly by module number
      assistants = data.sort((a, b) => {
        // Extract module numbers if they exist
        const moduleAMatch = a.vanityName.match(/Module\s+(\d+)/i);
        const moduleBMatch = b.vanityName.match(/Module\s+(\d+)/i);
        
        // If both have module numbers, compare them numerically
        if (moduleAMatch && moduleBMatch) {
          return parseInt(moduleAMatch[1], 10) - parseInt(moduleBMatch[1], 10);
        }
        
        // If only one has a module number, prioritize it
        if (moduleAMatch) return -1;
        if (moduleBMatch) return 1;
        
        // Otherwise sort alphabetically
        return a.vanityName.localeCompare(b.vanityName);
      });
      
      console.log("Sorted modules:", assistants.map(a => a.vanityName));
      
      const moduleIcons = document.getElementById('module-icons');
      // Clear existing options
      select.innerHTML = '';
      moduleIcons.innerHTML = '';

      assistants.forEach(assistant => {
        const option = document.createElement('option');
        option.value = assistant.vanityName;
        option.textContent = assistant.vanityName;
        select.appendChild(option);

        const icon = document.createElement('span');
        icon.className = 'module-icon not-generated';
        
        // Split the vanity name into words
        const words = assistant.vanityName.split(' ');
        let iconText;
        
        // Check if the second word (if it exists) is a number
        if (words.length > 1 && !isNaN(words[1]) && words[1].length <= 2) {
          // If second word is a number (like in "Module 1"), use it
          iconText = words[1];
        } else {
          // Otherwise use the first two letters of the first word
          // (like "Ti" for "Title Writer")
          iconText = words[0].substring(0, 2);
        }
        
        icon.textContent = iconText;
        icon.dataset.moduleId = String(assistant.vanityName);
        icon.title = assistant.vanityName;
        moduleIcons.appendChild(icon);
      });

      // Add special options at the end
      const allModulesOption = document.createElement('option');
      allModulesOption.value = 'all-modules';
      allModulesOption.textContent = pageTag ? `All ${pageTag.toUpperCase()} Modules` : 'All Modules';
      select.appendChild(allModulesOption);

      // Add option for processing only modules that haven't been generated yet
      const emptyModulesOption = document.createElement('option');
      emptyModulesOption.value = 'empty-modules';
      emptyModulesOption.textContent = pageTag ? `All Empty ${pageTag.toUpperCase()} Modules` : 'All Empty Modules';
      select.appendChild(emptyModulesOption);

      // Select first option by default (if available)
      if (select.options.length > 0) {
        select.selectedIndex = 0;
      }
      
      return token ? fetch('/outputs', {
        headers: { 'Authorization': `Bearer ${token}` }
      }) : Promise.resolve({ ok: false, json: () => ({ error: 'Not signed in' }) });
    })
    .then(res => {
      if (!res.ok) {
        return res.json().then(errorData => {
          throw new Error(`${errorData.error || 'Failed to fetch outputs'} (Status: ${res.status})`);
        });
      }
      return res.json();
    })
    .then(outputs => {
      if (!Array.isArray(outputs)) {
        console.error('Expected an array from /outputs, got:', outputs);
        return [];
      }
      
      // Group outputs by module name, to detect if there are any non-placeholder responses
      const moduleResponses = {};
      
      // Process all outputs to group by module
      outputs.forEach(output => {
        const moduleName = String(output.assistantVanityName);
        if (!moduleResponses[moduleName]) {
          moduleResponses[moduleName] = [];
        }
        moduleResponses[moduleName].push(output);
      });
      
      // Now check each module to see if all its responses are placeholders
      const modulesWithRealResponses = new Set();
      
      Object.entries(moduleResponses).forEach(([moduleName, moduleOutputs]) => {
        // If any output for this module is NOT a placeholder, add to modules with real responses
        const hasRealResponse = moduleOutputs.some(output => !isPlaceholder(output.output));
        if (hasRealResponse) {
          modulesWithRealResponses.add(moduleName);
          console.log(`Module ${moduleName} has at least one real response`);
        } else {
          console.log(`Module ${moduleName} has only placeholder responses or no content`);
        }
      });
      
      // Apply styling based on whether module has any real responses
      document.querySelectorAll('.module-icon').forEach(icon => {
        const moduleId = icon.dataset.moduleId;
        
        // If the module has any real response, make it green
        if (modulesWithRealResponses.has(moduleId)) {
          icon.classList.remove('not-generated');
          icon.classList.add('done');
        }
        // Otherwise it stays grey (not-generated)
      });
      
      loadHistory();
      setupInputToggle();
    })
    .catch(err => {
      console.error('Failed to load data:', err);
      aiResponse.innerText = `Error: Failed to load assistants or outputs - ${err.message}`;
      setupInputToggle();
    });

  function loadHistory() {
    if (!historyList) {
      console.error('history-list element not found');
      return;
    }
    if (!token) {
      historyList.innerHTML = '<li>Please sign in to view history</li>';
      return;
    }
    fetch('/outputs', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(errorData => {
            throw new Error(`${errorData.error || 'Failed to fetch outputs'} (Status: ${res.status})`);
          });
        }
        return res.json();
      })
      .then(outputs => {
        if (!Array.isArray(outputs)) {
          console.error('Expected an array from /outputs, got:', outputs);
          outputs = [];
        }
        
        // Filter outputs based on current page tag
        let filteredOutputs = outputs;
        
        // Only filter if pageTag exists and is not empty
        if (pageTag) {
          try {
            // Fetch assistants with the current page tag to create a filter list
            const tagUrl = `/assistants?tags=${pageTag}`;
            return fetch(tagUrl)
              .then(res => {
                if (!res.ok) throw new Error(`Failed to fetch assistants with tag: ${pageTag}`);
                return res.json();
              })
              .then(taggedAssistants => {
                if (!Array.isArray(taggedAssistants)) {
                  console.error('Expected array of assistants, got:', taggedAssistants);
                  return outputs; // Fall back to showing all outputs
                }
                
                // Create a set of valid module names (vanityNames) for this page tag
                const validModuleNames = new Set(taggedAssistants.map(a => a.vanityName));
                
                // Filter outputs to only include those from valid modules
                const originalCount = outputs.length;
                filteredOutputs = outputs.filter(output => {
                  return validModuleNames.has(output.assistantVanityName);
                });
                
                console.log(`Filtered from ${originalCount} to ${filteredOutputs.length} outputs for tag '${pageTag}'`);
                
                // Clear and render the filtered history list
                renderHistoryList(filteredOutputs);
                return filteredOutputs;
              })
              .catch(err => {
                console.error('Error filtering by tag:', err);
                // On error, fall back to showing all outputs
                renderHistoryList(outputs);
                return outputs;
              });
          } catch (err) {
            console.error('Error in tag filtering:', err);
            // On error, fall back to showing all outputs
            renderHistoryList(outputs);
            return outputs;
          }
        } else {
          // No tag filtering needed, render all outputs
          renderHistoryList(outputs);
          return outputs;
        }
      })
      .catch(err => {
        console.error('Failed to load history:', err);
        
        // Check if this is a network error that should use the unified error display
        if (err.message && (
          err.message.includes('Failed to fetch') || 
          err.message.includes('ECONNRESET') ||
          err.message.includes('Network request failed') ||
          err.message.includes('network error')
        )) {
          // Use the unified error display for network errors
          displayUnifiedError('History', err, null, () => {
            loadHistory(); // Retry callback to reload history
          });
        } else {
          // Use standard error display for non-network errors
          aiResponse.innerText = err.status === 429 
              ? 'Too many requests. Please wait a moment and try again.'
              : err.message.includes('exceeds the context window')
              ? err.message
              : err.creditsExhausted // Check for our special credit error flag
              ? 'Insufficient credits. Please purchase more credits to continue.'
              : `Request failed: ${err.message}. Please try again.`;
        }
      });
      
    // Helper function to render the history list with given outputs
    function renderHistoryList(outputs) {
      // Filter out outputs with "*****" as content
      const filteredOutputs = outputs.filter(output => output.output !== "*****");
      
      historyList.innerHTML = '';
      
      // If no outputs to display after filtering, show a message
      if (filteredOutputs.length === 0) {
        historyList.innerHTML = '<li class="p-3 text-gray-500 italic">No previous outputs found for this section.</li>';
        return;
      }
      
      // Update history toggle button with count
      const historyToggle = document.getElementById('history-toggle');
      if (historyToggle) {
        const heading = historyToggle.querySelector('h2');
        if (heading) {
          heading.textContent = `Previous Outputs (${filteredOutputs.length})`;
        }
      }
      
      // Render history items...
      filteredOutputs.forEach((output, index) => {
        const li = document.createElement('li');
        li.className = 'border border-gray-400 mb-2 rounded bg-white';
        li.dataset.id = output.id || index; // Use output ID if available, fallback to index
        
        const header = document.createElement('button');
        header.className = 'w-full text-left p-2 text-black hover:bg-gray-100 focus:outline-none';
        
        // Get the output text and calculate stats
        const outputText = output.output || '';
        const wordCount = outputText.trim().split(/\s+/).filter(word => word.length > 0).length;
        const charCount = outputText.length;
        
        // Create header content with date and module name in colored tags, followed by word/char count tags
        // Format date in DD/MM/YYYY format
        const createdDate = new Date(output.created_at);
        const dateFormatted = createdDate.toLocaleString(undefined, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        });
        
        // Check if this output has web citations
        const hasWebCitations = output.web_citations !== undefined && output.web_citations !== null;
        
        const headerHTML = `
          <span style="display: inline-block; background-color: #e6f7ff; color: #0055aa; padding: 2px 6px; border-radius: 4px; font-size: 0.8em;">${dateFormatted}</span>
          <span style="display: inline-block; background-color: #e6ffea; color: #00802b; padding: 2px 6px; border-radius: 4px; font-size: 0.8em; margin-left: 4px;">${output.vanity_name}</span>
          <span style="display: inline-block; background-color: #e2f2ff; color: #0066cc; padding: 2px 6px; border-radius: 4px; font-size: 0.8em; margin-left: 8px;">Words: ${wordCount}</span>
          <span style="display: inline-block; background-color: #ffede2; color: #cc6600; padding: 2px 6px; border-radius: 4px; font-size: 0.8em; margin-left: 4px;">Chars: ${charCount}</span>
          ${output.streaming_status === "completed" ? '<span style="display: inline-block; background-color: #e6e1ff; color: #5e3acc; padding: 2px 6px; border-radius: 4px; font-size: 0.8em; margin-left: 4px;">Stream</span>' : ''}
          ${hasWebCitations ? '<span style="display: inline-block; background-color: #fef3c7; color: #92400e; padding: 2px 6px; border-radius: 4px; font-size: 0.8em; margin-left: 4px;">Web</span>' : ''}
          <span class="copy-history-btn copy-icon" data-id="${index}" title="Copy to clipboard">${createCopyIcon()}</span>
        `;
        
        header.innerHTML = headerHTML;
        header.setAttribute('aria-expanded', 'false');
        header.setAttribute('aria-controls', `history-content-${index}`);
        const content = document.createElement('div');
        content.id = `history-content-${index}`;
        content.className = 'p-2 hidden text-black';
        
        // Create separate sections for input and response
        const hasFileInput = output.file_input && output.file_input.trim() !== '';
        const hasTextInput = output.text_input && output.text_input.trim() !== '';
        const hasInput = output.input && output.input.trim() !== '';
        
        let contentHTML = '';
        
        // Input section - all inputs wrapped in a single container with the hidden-input class
        contentHTML += '<div class="history-input-container hidden-input">';
        
        // File input
        if (hasFileInput) {
          // Sanitize file input to prevent XSS
          contentHTML += `<p><strong>File:</strong> ${sanitizeHTML(output.file_input) || ''}</p>`;
        }
        
        // Text input
        if (hasTextInput) {
          // Sanitize text input to prevent XSS
          contentHTML += `<p><strong>Text:</strong> ${sanitizeHTML(output.text_input) || ''}</p>`;
        }
        
        // Original combined input as fallback
        if (!hasFileInput && !hasTextInput && hasInput) {
          // Sanitize combined input to prevent XSS
          contentHTML += `<p><strong>Input:</strong> ${sanitizeHTML(output.input) || ''}</p>`;
        }
        
        contentHTML += '</div>';
        
        // Response section - always visible with sanitized HTML
        const sanitizedOutput = sanitizeHTML(output.output) || '';
        const transformedOutput = transformCitations(sanitizedOutput);
        contentHTML += `<p class="history-output"><strong>Response:</strong> <span class="formatted-content">${transformedOutput}</span></p>`;
        
        // Add citations section if available
        if (hasWebCitations && output.web_citations) {
          try {
            let citations = output.web_citations;
            
            // Try to parse if it's a string
            if (typeof citations === 'string') {
              try {
                citations = JSON.parse(citations);
              } catch (e) {
                console.error(`Failed to parse citations string:`, e);
                citations = null;
              }
            }
            
            // Only display if we have valid citations
            if (citations && Array.isArray(citations) && citations.length > 0) {
              contentHTML += `
                <div style="margin-top: 20px; padding-top: 15px; border-top: 1px solid #e5e7eb;">
                  <div style="font-weight: 600; margin-bottom: 8px;">Sources:</div>
                  <ol style="padding-left: 20px;">
                    ${citations.map((citation, i) => {
                      // Handle both object format and string URL format
                      const url = citation.url || citation;
                      const title = citation.title || url;
                      
                      return `
                        <li style="margin-bottom: 5px;">
                          <a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #3b82f6; text-decoration: none; word-break: break-all; font-weight: 500;">
                            ${title}
                          </a>
                        </li>
                      `;
                    }).join('')}
                  </ol>
                </div>
              `;
            }
          } catch (e) {
            console.error('Error processing citations:', e);
          }
        }
        
        content.innerHTML = contentHTML;
        
        header.addEventListener('click', () => {
          const isExpanded = header.getAttribute('aria-expanded') === 'true';
          header.setAttribute('aria-expanded', !isExpanded);
          content.classList.toggle('hidden');
        });
        li.appendChild(header);
        li.appendChild(content);
        historyList.appendChild(li);
      });

      // Group outputs by module name, to detect if there are any non-placeholder responses
      const moduleResponses = {};
      
      // Process all outputs to group by module
      filteredOutputs.forEach(output => {
        const moduleName = String(output.assistantVanityName);
        if (!moduleResponses[moduleName]) {
          moduleResponses[moduleName] = [];
        }
        moduleResponses[moduleName].push(output);
      });
      
      // Now check each module to see if all its responses are placeholders
      const modulesWithRealResponses = new Set();
      
      Object.entries(moduleResponses).forEach(([moduleName, moduleOutputs]) => {
        // If any output for this module is NOT a placeholder, add to modules with real responses
        const hasRealResponse = moduleOutputs.some(output => !isPlaceholder(output.output));
        if (hasRealResponse) {
          modulesWithRealResponses.add(moduleName);
        }
      });
      
      // Apply styling based on whether module has any real responses
      document.querySelectorAll('.module-icon').forEach(icon => {
        const moduleId = icon.dataset.moduleId;
        icon.className = 'module-icon';
        
        // If the module has any real response, make it green
        if (modulesWithRealResponses.has(moduleId)) {
          icon.classList.add('done');
        } else {
          icon.classList.add('not-generated');
        }
      });

      fetchCredits();
    }
  }

  function setupInputToggle() {
    const toggleButton = document.getElementById('toggle-inputs');
    const toggleIcon = document.getElementById('toggle-inputs-icon');
    
    if (!toggleButton || !toggleIcon) {
      console.error('Toggle button or icon not found in DOM. Ensure elements with id="toggle-inputs" and id="toggle-inputs-icon" exist.');
      return;
    }

    const toggleLabel = toggleButton.querySelector('span');
    if (!toggleLabel) {
      console.error('Toggle label (span) not found inside toggle button.');
      return;
    }

    let inputsVisible = false;

    toggleButton.addEventListener('click', () => {
      inputsVisible = !inputsVisible;
      const inputContainers = document.querySelectorAll('.history-input-container');

      if (inputsVisible) {
        inputContainers.forEach(elem => elem.classList.remove('hidden-input'));
        toggleLabel.textContent = 'Hide Inputs';
        toggleIcon.innerHTML = `
          <path d="M2 12s3-7 10-7 10 7 10 7z"></path>
          <path d="M12 12s-3-3-3-3"></path>
          <path d="M12 12s3-3 3-3"></path>
          <line x1="2" y1="2" x2="22" y2="22"></line>
        `;
      } else {
        inputContainers.forEach(elem => elem.classList.add('hidden-input'));
        toggleLabel.textContent = 'Show Inputs';
        toggleIcon.innerHTML = `
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        `;
      }
    });
  }

  function setModuleProcessing(moduleVanityName) {
    const icon = document.querySelector(`.module-icon[data-module-id="${moduleVanityName}"]`);
    if (icon) {
      icon.classList.remove('not-generated', 'done');
      icon.classList.add('processing');
    }
  }

  function setModuleDone(moduleVanityName) {
    const icon = document.querySelector(`.module-icon[data-module-id="${moduleVanityName}"]`);
    if (icon) {
      icon.classList.remove('not-generated', 'processing', 'error');
      icon.classList.add('done');
    }
  }
  
  function setModuleError(moduleVanityName) {
    // Find all module icons associated with this module
    document.querySelectorAll(`.module-icon[data-module-id="${moduleVanityName}"]`).forEach(icon => {
      // Remove other status classes first
      icon.classList.remove('processing', 'done');
      // Add error class
      icon.classList.add('error');
    });
    
    // Also add this style to the document if it doesn't exist
    if (!document.getElementById('error-module-style')) {
      const style = document.createElement('style');
      style.id = 'error-module-style';
      style.textContent = `
        .module-icon.error {
          background-color: #dc2626 !important;
          background: linear-gradient(135deg, #ef4444, #b91c1c) !important;
          color: #ffffff !important;
        }
      `;
      document.head.appendChild(style);
    }
  }

  /**
   * Display cancellation message while preserving content from completed modules.
   * Immediately replaces any blue/green processing messages.
   * @param {boolean} preserveExistingOutput - Whether to keep existing outputs below the cancellation message
   */
  function displayCancellationMessage(preserveExistingOutput = true) {
    if (isCancelling) return;
    
    isCancelling = true;
    cancellationTime = Date.now();
    
    const normalContainer = document.getElementById('normal-response');
    
    // Store existing content excluding processing message headers
    let existingOutput = '';
    if (preserveExistingOutput) {
      // Create a temp container to manipulate the DOM
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = normalContainer.innerHTML;
      
      // Immediately remove ALL processing message headers (blue, green)
      const processingHeaders = tempContainer.querySelectorAll('.bg-blue-100, .bg-green-100, .bg-orange-100');
      processingHeaders.forEach(header => {
        const parentDiv = header.closest('.mb-4.relative.overflow-hidden');
        if (parentDiv) parentDiv.remove();
      });
      
      // Keep only the actual content, not the status messages
      existingOutput = tempContainer.innerHTML;
    }
    
    // Immediately replace with the cancellation message
    normalContainer.innerHTML = `
      <div class="mb-4 relative overflow-hidden cancellation-message">
        <div class="bg-orange-100 text-orange-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
          Processing Cancelled
          <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
        </div>
      </div>
      <div id="cancelled-output-container">${existingOutput}</div>
    `;
    
    // Reset state flags
    isProcessingAllModules = false;
    window.isBatchProcessing = false;
    
    // Update the button state immediately
    aiButton.textContent = 'Submit';
    aiButton.classList.remove('bg-red-600', 'hover:bg-red-700');
    aiButton.classList.add('bg-[#e50047]', 'hover:bg-[#cc0040]');
    aiButton.setAttribute('data-action', 'submit');
    
    // Fully reset cancellation state after a delay
    setTimeout(() => {
      isCancelling = false;
      updateSubmitButtonState();
    }, 500);
  }

  /**
   * Handle response that arrives after cancellation by appending it to the cancelled output section.
   * @param {string} moduleVanityName - Name of the module the response is for
   * @param {string} response - The response text content
   * @returns {boolean} - True if the response was handled as post-cancellation, false otherwise
   */
  function handlePostCancellationResponse(moduleVanityName, response) {
    console.log(`Post-cancellation response received for ${moduleVanityName}`);
    
    // If we don't have a cancellation in progress, just ignore
    if (!isCancelling && !cancellationTime) return false;
    
    // Get the container for cancelled outputs
    const cancelledOutputContainer = document.getElementById('cancelled-output-container');
    if (!cancelledOutputContainer) return false;
    
    // Add this response to the cancelled output container
    const responseDiv = document.createElement('div');
    responseDiv.className = 'mb-4 post-cancellation-response';
    responseDiv.innerHTML = `
      <h4 class="font-bold">${sanitizeHTML(moduleVanityName)}</h4>
      <p>${sanitizeHTML(response)}</p>
    `;
    
    cancelledOutputContainer.appendChild(responseDiv);
    return true;
  }

  // Add CSS for response containers
  const responseContainerStyle = document.createElement('style');
  responseContainerStyle.textContent = `
    #ai-response {
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #ffffff;
      border: 1px solid #e5e7eb;
    }
    
    .response-container {
      width: 100%;
      min-height: 100px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
    }
    
    #normal-response, #streaming-response {
      background-color: transparent;
      border: none;
    }
    
    .hidden {
      display: none !important;
    }
    
    .formatted-content {
      line-height: 1.5;
    }
    
    .streaming-container {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  `;
  document.head.appendChild(responseContainerStyle);

  function setLoading(isLoading, message = 'Processing...') {
    // Don't update UI if we're in cancellation mode unless it's a cancellation message
    if ((isCancelling || isCancelled) && message !== 'Operation cancelled by user') {
      return;
    }
    
    if (isLoading) {
      // Show loading in normal container only
      const normalContainer = document.getElementById('normal-response');
      normalContainer.innerHTML = `
        <div class="mb-4 relative overflow-hidden">
          <div class="bg-blue-100 text-blue-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
            ${message}
            <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
          </div>
        </div>
      `;
      
      // Add the shine effect CSS if it doesn't exist
      if (!document.getElementById('shine-effect-style')) {
        const style = document.createElement('style');
        style.id = 'shine-effect-style';
        style.textContent = `
          @keyframes shine {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }
          .shine-effect {
            background: linear-gradient(
              90deg, 
              rgba(255,255,255,0) 0%, 
              rgba(255,255,255,0.6) 50%, 
              rgba(255,255,255,0) 100%
            );
            animation: shine 2s infinite linear;
            pointer-events: none;
          }
        `;
        document.head.appendChild(style);
      }
      
      // Hide streaming container
      document.getElementById('streaming-response').classList.add('hidden');
      normalContainer.classList.remove('hidden');
      
      // Transform button to Cancel
      aiButton.disabled = false;
      aiButton.textContent = 'Cancel';
      aiButton.classList.remove('bg-[#e50047]', 'hover:bg-[#cc0040]', 'bg-gray-400', 'opacity-50', 'cursor-not-allowed');
      aiButton.classList.add('bg-red-600', 'hover:bg-red-700');
      aiButton.setAttribute('data-action', 'cancel');
    } else {
      // Only clear the normal container if no message is provided
      if (!message) {
        document.getElementById('normal-response').innerHTML = '';
      } else if (message === 'Operation cancelled by user') {
        // Use our dedicated cancellation message function
        displayCancellationMessage(true);
        return;
      }
      
      // Reset button to Submit
      aiButton.textContent = 'Submit';
      aiButton.classList.remove('bg-red-600', 'hover:bg-red-700');
      aiButton.setAttribute('data-action', 'submit');
      
      updateSubmitButtonState();
    }
  }

  /**
   * Display a unified error message for any error condition
   * @param {string} moduleVanityName - The name of the module being processed
   * @param {Error} error - The error object
   * @param {string} content - The content being processed
   * @param {Function} retryCallback - Function to call when retry button is clicked
   * @param {boolean} isAllModules - Whether this is part of "Process All Modules"
   * @param {number} moduleIndex - Index of module in batch processing (if applicable)
   */
  function displayUnifiedError(moduleVanityName, error, content, retryCallback, isAllModules = false, moduleIndex = null) {
    console.error(`Error processing ${moduleVanityName}:`, error.message);
    
    // Remove module from in-progress tracking
    window.modulesInProgress.delete(moduleVanityName);
    
    // Mark module as error in UI
    setModuleError(moduleVanityName);
    
    // Generate a unique ID for this retry button
    const retryBtnId = `retry-btn-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    
    // Display the standard error message with the same styling as other status messages
    const normalContainer = document.getElementById('normal-response');
    normalContainer.innerHTML = `
      <div class="mb-4 relative overflow-hidden">
        <div class="bg-red-100 text-red-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
          Oops, there was an error.
          <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
        </div>
      </div>
      <div class="flex justify-center mt-4">
        <button id="${retryBtnId}" class="bg-[#e50047] hover:bg-[#cc0040] text-white px-4 py-2 rounded">Try Again</button>
      </div>
      <div class="mt-4 text-red-600 text-sm px-4 py-3 bg-red-50 rounded border border-red-100">
        <p class="font-medium mb-2">Troubleshooting tips:</p>
        <ul class="list-disc ml-5 space-y-1">
          <li>Use less text and smaller file inputs</li>
          <li>Do not process Modules in multiple browser windows</li>
          <li>Make sure you have stable internet connectivity</li>
          <li>Contact support: <a href="mailto:question@chateic.com" class="underline">question@chateic.com</a></li>
        </ul>
      </div>
    `;
    
    // Add the shine effect CSS if it doesn't exist
    if (!document.getElementById('shine-effect-style')) {
      const style = document.createElement('style');
      style.id = 'shine-effect-style';
      style.textContent = `
        @keyframes shine {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
        .shine-effect {
          background: linear-gradient(
            90deg, 
            rgba(255,255,255,0) 0%, 
            rgba(255,255,255,0.6) 50%, 
            rgba(255,255,255,0) 100%
          );
          animation: shine 2s infinite linear;
          pointer-events: none;
        }
      `;
      document.head.appendChild(style);
    }
    
    // Reset processing flags
    if (isAllModules) {
      isProcessingAllModules = false;
    }
    
    // Hide streaming container
    document.getElementById('streaming-response').classList.add('hidden');
    normalContainer.classList.remove('hidden');
    
    // Attach the event listener to the retry button
    setTimeout(() => {
      const retryButton = document.getElementById(retryBtnId);
      if (retryButton) {
        retryButton.addEventListener('click', () => {
          if (retryCallback) {
            // Show processing state again
            setLoading(true, 'Processing...');
            
            // Execute the retry callback
            retryCallback();
          }
        });
      }
    }, 100);
    
    updateSubmitButtonState();
  }

  // Function to display output without spinner during delay periods
  function displayOutput(content) {
    // If already cancelled, use the post-cancellation handler
    if (isCancelled || isCancelling) {
      // Try to extract the current module name from an existing heading in the content 
      const currentModuleName = select.options[select.selectedIndex].text || "Module";
      return handlePostCancellationResponse(currentModuleName, content);
    }

    const normalContainer = document.getElementById('normal-response');
    normalContainer.innerHTML = `
      <div class="mb-4 relative overflow-hidden">
        <div class="bg-green-100 text-green-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
          Processing of All Modules in progress...
          <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
        </div>
      </div>
      <div>${content}</div>
    `;
    
    // Add the shine effect CSS if it doesn't exist
    if (!document.getElementById('shine-effect-style')) {
      const style = document.createElement('style');
      style.id = 'shine-effect-style';
      style.textContent = `
        @keyframes shine {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
        .shine-effect {
          background: linear-gradient(
            90deg, 
            rgba(255,255,255,0) 0%, 
            rgba(255,255,255,0.6) 50%, 
            rgba(255,255,255,0) 100%
          );
          animation: shine 2s infinite linear;
          pointer-events: none;
        }
      `;
      document.head.appendChild(style);
    }
    
    // Hide streaming container
    document.getElementById('streaming-response').classList.add('hidden');
    normalContainer.classList.remove('hidden');
    
    // Keep cancel button active during batch processing
    aiButton.disabled = false;
    aiButton.textContent = 'Cancel';
    aiButton.classList.remove('bg-[#e50047]', 'hover:bg-[#cc0040]', 'bg-gray-400', 'opacity-50', 'cursor-not-allowed');
    aiButton.classList.add('bg-red-600', 'hover:bg-red-700');
    aiButton.setAttribute('data-action', 'cancel');
  }

  // Function to display output for empty modules processing
  function displayEmptyOutput(content) {
    const normalContainer = document.getElementById('normal-response');
    normalContainer.innerHTML = `
      <div class="mb-4 relative overflow-hidden">
        <div class="bg-green-100 text-green-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
          Processing of All Empty Modules in progress...
          <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
        </div>
      </div>
      <div>${content}</div>
    `;
    
    // Add the shine effect CSS if it doesn't exist
    if (!document.getElementById('shine-effect-style')) {
      const style = document.createElement('style');
      style.id = 'shine-effect-style';
      style.textContent = `
        @keyframes shine {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
        .shine-effect {
          background: linear-gradient(
            90deg, 
            rgba(255,255,255,0) 0%, 
            rgba(255,255,255,0.6) 50%, 
            rgba(255,255,255,0) 100%
          );
          animation: shine 2s infinite linear;
          pointer-events: none;
        }
      `;
      document.head.appendChild(style);
    }
    
    // Hide streaming container
    document.getElementById('streaming-response').classList.add('hidden');
    normalContainer.classList.remove('hidden');
    
    // Keep cancel button active during batch processing
    aiButton.disabled = false;
    aiButton.textContent = 'Cancel';
    aiButton.classList.remove('bg-[#e50047]', 'hover:bg-[#cc0040]', 'bg-gray-400', 'opacity-50', 'cursor-not-allowed');
    aiButton.classList.add('bg-red-600', 'hover:bg-red-700');
    aiButton.setAttribute('data-action', 'cancel');
  }

  // Function to reload module icon states based on actual server data
  function reloadModuleIconStates() {
    if (!token) return;
    
    // Fetch latest outputs data from server
    fetch('/outputs', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(res => res.ok ? res.json() : [])
      .then(outputs => {
        if (!Array.isArray(outputs)) {
          console.error('Expected array from /outputs, got:', outputs);
          return;
        }
        
        // Group outputs by module name
        const moduleResponses = {};
        outputs.forEach(output => {
          const moduleName = String(output.assistantVanityName);
          if (!moduleResponses[moduleName]) {
            moduleResponses[moduleName] = [];
          }
          moduleResponses[moduleName].push(output);
        });
        
        // Determine which modules have non-placeholder responses
        const modulesWithRealResponses = new Set();
        Object.entries(moduleResponses).forEach(([moduleName, moduleOutputs]) => {
          // Use existing isPlaceholder function
          const hasRealResponse = moduleOutputs.some(output => !isPlaceholder(output.output));
          if (hasRealResponse) {
            modulesWithRealResponses.add(moduleName);
          }
        });
        
        // Update the module icons based on actual state
        document.querySelectorAll('.module-icon').forEach(icon => {
          const moduleId = icon.dataset.moduleId;
          icon.className = 'module-icon';
          
          if (modulesWithRealResponses.has(moduleId)) {
            icon.classList.add('done');
          } else {
            icon.classList.add('not-generated');
          }
        });
        
        console.log('Module icon states reloaded');
      })
      .catch(err => console.error('Failed to reload module icon states:', err));
  }

  async function convertFileToText(file) {
    const MAX_SIZE_MB = 20;
    const allowedTypes = ['pdf', 'docx', 'doc'];

    const mimeTypeToExtension = {
      'application/pdf': 'pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/msword': 'doc',
    };

    let fileType = file.type;
    if (fileType && mimeTypeToExtension[fileType]) {
      fileType = mimeTypeToExtension[fileType];
    } else {
      fileType = file.name.split('.').pop().toLowerCase();
    }

    console.log(`Processing file: ${file.name}, Type: ${fileType}, Size: ${(file.size / 1024 / 1024).toFixed(2)} MB`);

    if (file.size > MAX_SIZE_MB * 1024 * 1024) {
      throw new Error(`File size exceeds ${MAX_SIZE_MB} MB limit`);
    }

    if (!allowedTypes.includes(fileType)) {
      throw new Error('Unsupported file type. Please upload a PDF, DOC, or DOCX file.');
    }

    try {
      if (fileType === 'pdf') {
        console.time('PDF Conversion');
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
        let text = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          text += textContent.items.map(item => item.str).join(' ') + '\n';
        }
        console.timeEnd('PDF Conversion');
        return text;
      } else if (fileType === 'docx' || fileType === 'doc') {
        console.time('Word Conversion (mammoth)');
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        console.timeEnd('Word Conversion (mammoth)');
        return result.value;
      }
    } catch (err) {
      console.error(`Error converting file ${file.name} (${fileType}):`, err);
      throw new Error(`Failed to convert file: ${err.message}`);
    }
  }

  // Update retryFetch function timeout parameter
  async function retryFetch(url, options, maxRetries = 3, baseDelayMs = 5000, timeoutMs = 480000) {
    // DEBUG CODE - Print detailed environment info
    const hostname = window.location.hostname;
    const isProd = hostname === 'prmvl.com' || hostname.includes('prmvl.com') || 
                  hostname === 'chateic.com' || hostname.includes('chateic.com');
    console.error(`📢 retryFetch DEBUG - Hostname: ${hostname}, isProd: ${isProd}`);
    console.error(`📢 retryFetch DEBUG - Call with URL: ${url}`);
    
    if (!token) throw new Error('Authentication required: Please sign in');
    
    let currentAbortController;
    let timeoutId;
    
    // Replace all endpoint logic to use only /ask-ai-responses
    let endpointsToTry = [];
    
    // If URL was /ask-ai, convert it to /ask-ai-responses
    if (url === '/ask-ai') {
      url = '/ask-ai-responses';
    }
    
    // Only use the specified URL (which is now guaranteed to not be /ask-ai)
    endpointsToTry = [url];
    console.error(`Using endpoint: ${url}`);
    
    console.error(`📢 Final endpoints to try: ${JSON.stringify(endpointsToTry)}`);
    
    // Try each endpoint in order until one works
    let lastError = null;
    
    for (const endpoint of endpointsToTry) {
      // Try multiple attempts for each endpoint
      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        // Check if operation was cancelled
        if (isCancelled) {
          throw new Error('Operation cancelled by user');
        }
        
        currentAbortController = new AbortController();
        
        // Set timeout for this attempt
        timeoutId = setTimeout(() => {
          currentAbortController.abort();
        }, timeoutMs);
        
        console.log(`Trying endpoint ${endpoint}, attempt ${attempt}/${maxRetries}`);
        
        try {
          const response = await fetch(endpoint, {
            ...options,
            headers: {
              ...options.headers,
              'Authorization': `Bearer ${token}`,
              'Content-Type': options.method === 'POST' ? 'application/json' : undefined,
              'X-Batch-Request': window.isBatchProcessing ? 'true' : undefined
            },
            signal: currentAbortController.signal,
          });
          
          clearTimeout(timeoutId);
          
          // Log the response status for debugging
          console.error(`📢 Response from ${endpoint}: status ${response.status}, ok: ${response.ok}, content-type: ${response.headers.get('content-type')}`);
          
          // Handle 404 Not Found errors specifically
          if (response.status === 404) {
            console.error(`🚨 404 Not Found error for ${endpoint}`);
            
            // If we have more endpoints to try, don't throw an error yet
            if (endpoint !== endpointsToTry[endpointsToTry.length - 1]) {
              console.error(`🚨 Got 404 for ${endpoint}, will try next endpoint`);
              lastError = new Error(`Endpoint ${endpoint} not found (404)`);
              break; // Break out of attempts loop and try next endpoint
            }
            
            // If this is the last endpoint, throw the 404 error
            throw new Error(`Endpoint ${endpoint} not found (404). The API may not be available.`);
          }
          
          // Check for specific error statuses before continuing
          if (response.status === 413) {
            console.error(`🚨 413 Payload Too Large error detected for ${endpoint}`);
            throw new Error('The entered text exceeds the context window of the AI. Please try again with less text.');
          }
          
          if (response.status === 429) {
            console.error(`🚨 429 Too Many Requests error for ${endpoint}`);
            
            // Get retry-after header or default to 5 seconds
            const retryAfter = response.headers.get('Retry-After');
            const waitTime = retryAfter ? parseInt(retryAfter, 10) * 1000 : 5000;
            
            console.log(`Rate limit exceeded. Waiting ${waitTime/1000} seconds before retrying...`);
            setLoading(true, `Rate limit exceeded. Waiting ${waitTime/1000} seconds before retrying...`);
            
            await new Promise(resolve => setTimeout(resolve, waitTime));
            
            // Don't count this as a failed attempt, just try again after waiting
            console.log(`Waited ${waitTime/1000}s for rate limit, retrying request`);
            continue; // Retry this same endpoint instead of throwing
          } else if (response.status === 402) {
            console.error('🚨 402 Payment Required error: Insufficient credits');
            const creditError = new Error('Insufficient credits. Please purchase more credits to continue.');
            creditError.creditsExhausted = true;
            throw creditError;
          }
          
          // CRITICAL FIX: Only report success if response.ok is true
          if (!response.ok) {
            console.error(`🚨 HTTP error ${response.status} for ${endpoint}`);
            throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
          }
          
          // Only get here if the response was successful
          console.error(`✅ Fetch succeeded with status ${response.status} on attempt ${attempt} for ${endpoint}`);
          return response;
          
        } catch (err) {
          // If the user cancelled, abort immediately
          if (err.name === 'AbortError' || err.message.includes('abort') || err.message.includes('cancel')) {
            console.warn(`Request to ${endpoint} was aborted:`, err.message);
            lastError = new Error('Request timed out or was cancelled');
            continue; // Try next endpoint
          }
          
          // Specific OpenAI API timeout detection 
          if (err.message && (
              (err.message.includes('Request timed out') && err.message.includes('500 Internal Server Error')) || 
              (err.message.includes('OpenAI API') && err.message.includes('timed out'))
             )) {
            console.error(`🚨 OpenAI API timeout detected for ${assistantVanityName}, immediately retrying...`);
            lastError = err;
            lastError.isOpenAITimeout = true; // Add marker for later identification
            break; // Break out of endpoints loop to retry this attempt immediately
          }
          
          console.error(`Error with ${endpoint}:`, err);
          lastError = err;
          
          // If we're on the last endpoint, don't suppress the error
          if (endpoint === endpointsToTry[endpointsToTry.length - 1]) {
            // We've tried all endpoints, so the error is final
            break;
          }
        }
      }
    }
    
    // If we got here, all endpoints and attempts failed
    throw lastError || new Error('All endpoints failed');
  }

  // Add this function before processSingleModule
  // Check if a module was actually generated despite a timeout/error
  async function checkIfModuleWasGenerated(moduleVanityName) {
    console.log(`Checking if ${moduleVanityName} was generated on the server...`);
    
    if (!token) return false;
    
    try {
      const response = await fetch('/outputs', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (!response.ok) {
        console.error(`Failed to check outputs: ${response.status}`);
        return false;
      }
      
      const outputs = await response.json();
      if (!Array.isArray(outputs)) {
        console.error('Expected an array from /outputs');
        return false;
      }
      
      // Get current time and time 10 minutes ago (increased from 6 minutes)
      const now = new Date();
      const tenMinutesAgo = new Date(now.getTime() - 10 * 60 * 1000);
      
      // Check if this module exists in the outputs and was created recently (within the last 10 minutes)
      const found = outputs.some(output => {
        const isMatchingModule = output.assistantVanityName === moduleVanityName || 
                                 output.vanity_name === moduleVanityName;
        
        if (!isMatchingModule) return false;
        
        // If there's no timestamp, we can't verify when it was created
        if (!output.created_at) {
          console.warn(`Module ${moduleVanityName} found but has no creation timestamp`);
          return false;
        }
        
        // Check if the output was created within the last 10 minutes
        const createdAt = new Date(output.created_at);
        const isRecent = createdAt >= tenMinutesAgo;
        
        if (isMatchingModule && !isRecent) {
          console.warn(`Found ${moduleVanityName} but it's from ${createdAt.toLocaleString()}, which is too old`);
        }
        
        return isMatchingModule && isRecent;
      });
      
      if (found) {
        console.log(`Module ${moduleVanityName} was recently generated on the server`);
        window.completedModuleCache.add(moduleVanityName);
        return true;
      }
      
      console.log(`Module ${moduleVanityName} not found or not recently generated`);
      return false;
    } catch (err) {
      console.error('Error checking if module was generated:', err);
      return false;
    }
  }

  async function processSingleModule(content, assistantVanityName, maxRetries = 3, baseDelayMs = 5000, skipDisplay = false) {
    if (!token) {
      aiResponse.innerText = 'Error: Please sign in to process module';
      window.location.href = '/getstarted.html';
      return;
    }
    
    if (!content) {
      aiResponse.innerText = 'Error: Please enter some text to process';
      return;
    }
    
    if (!assistantVanityName) {
      aiResponse.innerText = 'Error: Missing assistant name';
      return;
    }
    
    // Set module to processing state
    setModuleProcessing(assistantVanityName);
    window.modulesInProgress.add(assistantVanityName);
    
    console.log(`Processing module ${assistantVanityName} (will always process even if previously generated)`);
    
    // Determine which endpoints to try
    const endpoints = ['/ask-ai-responses'];
    
    // Reset global attempt counter if this is a new single module request (not part of batch)
    if (!window.isBatchProcessing) {
      globalAttemptCounter = 0;
    }
    
    // For each endpoint, try with retries
    let success = false;
    
    // Track retry attempts specifically for network errors
    let networkRetryAttempts = 0;
    const maxNetworkRetries = 3; // Always try 3 times for network errors
    
    for (const endpoint of endpoints) {
      console.error(`🔍 DEBUG - Endpoint ${endpoints.indexOf(endpoint) + 1}/${endpoints.length}: ${endpoint}`);
      if (success) {
        console.error(`✅ Already succeeded with previous endpoint, skipping ${endpoint}`);
        break; // Skip if we already succeeded with a previous endpoint
      }
      
      // Reset network retry counter for each endpoint
      networkRetryAttempts = 0;
      
      while (networkRetryAttempts < maxNetworkRetries) {
        // Increment global attempt counter for UI/logging
        globalAttemptCounter = networkRetryAttempts + 1;
        
        console.log(`Trying endpoint ${endpoint} (Attempt ${globalAttemptCounter}/${maxNetworkRetries})`);
        
        // Show processing status with attempt number if not in skip display mode
        if (!skipDisplay) {
          setLoading(true, `Processing ${assistantVanityName} (Attempt ${globalAttemptCounter} of ${maxNetworkRetries})...`);
        }
        
        try {
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token || getCookie('token')}`,
              'X-Session-Id': sessionId
            },
            body: JSON.stringify({
              text: content,
              assistantVanityName: assistantVanityName
            })
          });

          console.log(`Response status: ${response.status}, Content-Type: ${response.headers.get('Content-Type')}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const contentType = response.headers.get('Content-Type');
          if (contentType && contentType.includes('text/event-stream')) {
            // Force display for streaming responses
            skipDisplay = false;
            
            // For streaming responses, use streaming container
            if (!skipDisplay) {
              document.getElementById('streaming-response').classList.remove('hidden');
              document.getElementById('normal-response').classList.add('hidden');
            }
            const result = await handleStreamingResponse(response, skipDisplay);
            if (!result) {
              throw new Error('No data received from stream');
            }
            
            // Set module to done state after streaming completes
            setModuleDone(assistantVanityName);
            window.modulesInProgress.delete(assistantVanityName);
            
            // Success flag to avoid trying other endpoints
            success = true;
            
            return result;
          } else {
            // For normal responses, use normal container
            if (!skipDisplay) {
              document.getElementById('normal-response').classList.remove('hidden');
              document.getElementById('streaming-response').classList.add('hidden');
            }
            // Handle non-streaming response
            const data = await response.json();
            if (!response.ok) {
              throw new Error(data.error || 'Failed to process module');
            }
            
            // Success - module processed correctly
            console.log(`Module ${assistantVanityName} processed successfully`);
            setModuleDone(assistantVanityName);
            window.modulesInProgress.delete(assistantVanityName);
            
            // Display the response only if not skipping display
            if (!skipDisplay) {
              document.getElementById('normal-response').innerHTML = sanitizeHTML(data.response);
              setLoading(false);
            }
            
            // Success flag to avoid trying other endpoints
            success = true;
            
            // Always update credits and history regardless of skipDisplay
            await fetchCredits();
            loadHistory();
            
            return data.response;
          }
        } catch (error) {
          console.error('Processing error:', error);
          
          // Check for network-related errors
          const isNetworkError = (
            error.message.includes('Failed to fetch') || 
            error.message.includes('Network request failed') ||
            error.message.includes('ERR_CONNECTION_REFUSED') ||
            error.message.includes('ERR_NETWORK_CHANGED') ||
            error.message.includes('NetworkError')
          );
          
          // Check for specific HTTP error codes we want to handle specially
          const is429Error = error.message.includes('HTTP error! status: 429');
          
          // For network errors, attempt retry
          if (isNetworkError) {
            networkRetryAttempts++;
            
            if (networkRetryAttempts < maxNetworkRetries) {
              console.log(`Network error encountered. Retrying (${networkRetryAttempts}/${maxNetworkRetries})...`);
              
              // Wait before retry with exponential backoff
              const delay = baseDelayMs * Math.pow(2, networkRetryAttempts - 1);
              console.log(`Waiting ${delay/1000} seconds before retry...`);
              await new Promise(resolve => setTimeout(resolve, delay));
              
              // Continue to next retry attempt
              continue;
            }
          }
          
          // For 429 Too Many Requests errors, always show error regardless of skipDisplay
          if (is429Error) {
            // Override skipDisplay for this critical error
            displayUnifiedError(assistantVanityName, error, content, () => {
              // Reset and retry the module
              return processSingleModule(content, assistantVanityName, maxRetries, baseDelayMs);
            });
            throw error;
          }
          
          // If we've exhausted retries or it's not a network error
          if (!skipDisplay) {
            // Use the unified error display
            displayUnifiedError(assistantVanityName, error, content, () => {
              // Reset and retry the module
              return processSingleModule(content, assistantVanityName, maxRetries, baseDelayMs);
            });
          }
          
          // Re-throw so the caller can handle it
          throw error;
        } finally {
          // Re-enable the button after processing is complete or if there's an error
          if (!skipDisplay) {
            aiButton.disabled = false;
            aiButton.classList.remove('bg-gray-400', 'opacity-50', 'cursor-not-allowed');
            updateSubmitButtonState();
          }
        }
        
        // If we didn't hit a continue statement, break the retry loop
        break;
      }
    }
  }

  async function handleStreamingResponse(response, skipDisplay = false) {
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let accumulatedContent = '';
    
    try {
      // Show streaming container, hide normal container
      if (!skipDisplay) {
        document.getElementById('streaming-response').classList.remove('hidden');
        document.getElementById('normal-response').classList.add('hidden');
      }
      
      // Always clear the streaming response container before starting
      document.getElementById('streaming-response').innerHTML = '';
      
      // Create streaming container once
      const streamingContainer = document.querySelector('.streaming-container') || 
        (() => {
          const container = document.createElement('div');
          container.className = 'streaming-container formatted-content';
          // The clearing is now done above, before this check
          document.getElementById('streaming-response').appendChild(container);
          return container;
        })();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const eventText = line.slice(6);
            if (eventText === '[DONE]') {
              continue;
            }
            
            try {
              const data = JSON.parse(eventText);
              
              if (data.type === 'error') {
                throw new Error(data.message || 'Stream error occurred');
              }
              
              if (data.content) {
                // Strip HTML tags and decode HTML entities
                const textContent = data.content
                  .replace(/<[^>]*>/g, '') // Remove HTML tags
                  .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
                  .replace(/&lt;/g, '<') // Replace &lt; with <
                  .replace(/&gt;/g, '>') // Replace &gt; with >
                  .replace(/&amp;/g, '&') // Replace &amp; with &
                  .replace(/&quot;/g, '"') // Replace &quot; with "
                  .replace(/&#39;/g, "'") // Replace &#39; with '
                  .replace(/\n/g, '<br>'); // Replace newlines with <br>
                
                accumulatedContent += textContent;
                // Update UI with formatted text content during streaming
                if (!skipDisplay) {
                  streamingContainer.innerHTML = accumulatedContent;
                }
              }
            } catch (e) {
              console.error('Error parsing SSE event:', e);
            }
          }
        }
      }
      
      // Final update with sanitized content
      if (accumulatedContent) {
        const sanitizedContent = sanitizeHTML(accumulatedContent);
        if (!skipDisplay) {
          streamingContainer.innerHTML = sanitizedContent;
          
          // Update credits and history after completion
          await fetchCredits();
          loadHistory();
        }
      }
      
      // Reset button state after streaming is complete
      if (!skipDisplay) {
        setLoading(false);
      }
      
      return accumulatedContent;
    } catch (error) {
      console.error('Error processing stream:', error);
      throw error;
    } finally {
      reader.releaseLock();
    }
  }

  // Helper function to handle module errors
  function handleModuleError(moduleVanityName, err, content = null) {
    console.error(`Error processing ${moduleVanityName}:`, err.message);
    
    // Special case for credit issues - these need special handling
    if (err.creditsExhausted) {
      // Update credit UI with the latest from server
      fetchCredits();
      
      // Special error display for credit issues
      const normalContainer = document.getElementById('normal-response');
      normalContainer.innerHTML = `
        <div class="mb-4 relative overflow-hidden">
          <div class="bg-red-100 text-red-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
            Oops, there was an error.
            <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
          </div>
        </div>
        <div class="flex flex-col items-center mt-4">
          <p class="mb-3">You've used all your available credits.</p>
          <a href="/getstarted.html" class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded">Purchase Credits</a>
        </div>
      `;
      
      // Add shine effect if needed
      if (!document.getElementById('shine-effect-style')) {
        const style = document.createElement('style');
        style.id = 'shine-effect-style';
        style.textContent = `
          @keyframes shine {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }
          .shine-effect {
            background: linear-gradient(
              90deg, 
              rgba(255,255,255,0) 0%, 
              rgba(255,255,255,0.6) 50%, 
              rgba(255,255,255,0) 100%
            );
            animation: shine 2s infinite linear;
            pointer-events: none;
          }
        `;
        document.head.appendChild(style);
      }
      
      // Remove module from in-progress tracking
      window.modulesInProgress.delete(moduleVanityName);
      
      // Mark module as error in UI
      setModuleError(moduleVanityName);
      
      // Hide streaming container
      document.getElementById('streaming-response').classList.add('hidden');
      document.getElementById('normal-response').classList.remove('hidden');
      
      return { errorMessage: 'Insufficient credits', isCreditsError: true };
    }
    
    // For all other errors, use the unified error display
    displayUnifiedError(moduleVanityName, err, content, () => {
      // Retry callback
      processSingleModule(content, moduleVanityName, 3);
    });
    
    return {
      errorMessage: 'Error occurred',
      isCreditsError: false
    };
  }

  // Helper function to check for successful module generation after an error
  async function checkForSuccessAfterError(moduleVanityName, error) {
    console.log(`Checking if ${moduleVanityName} completed in the background despite error...`);
    
    // For context window errors, never show success message
    if (error && error.message && error.message.includes('exceeds the context window')) {
      console.error(`Context window error for ${moduleVanityName}. Not showing success message.`);
      setLoading(false, `<p>Error: ${error.message}</p>`);
      return false;
    }
    
    // Wait a bit before checking
    await new Promise(resolve => setTimeout(resolve, 10000));
    
    // Check if the module was generated successfully despite the error
    const wasGenerated = await checkIfModuleWasGenerated(moduleVanityName);
    if (wasGenerated) {
      console.log(`Module ${moduleVanityName} was successfully generated despite error!`);
      setModuleDone(moduleVanityName);
      window.modulesInProgress.delete(moduleVanityName);
      
      // Add to our cache of generated modules
      window.completedModuleCache.add(moduleVanityName);
      
      // Display success and load from history
      setLoading(false, `<p>Module ${moduleVanityName} processed successfully despite error!</p>`);
      await fetchCredits();
      loadHistory();
      return true;
    }
    
    return false;
  }

  async function processAllModules(content, startIndex = 0) {
    if (!token) {
      aiResponse.innerText = 'Error: Please sign in to process all modules';
      window.location.href = '/getstarted.html';
      return;
    }
    
    const totalModules = assistants.length;
    isProcessingAllModules = true;
    isCancelled = false; // Reset cancellation state at the start
    currentAbortController = null; // Reset abort controller
    window.isBatchProcessing = true; // Set batch processing flag
    globalAttemptCounter = 0; // Reset global attempt counter at start of batch processing
    updateSubmitButtonState();
    console.log(`Processing all ${totalModules} modules (will always process all modules, even if previously generated)`);

    // First, check which modules already exist on the server
    console.log("Checking which modules already exist on the server...");

    // We'll use our global cache instead of a local set
    const completedModules = window.completedModuleCache;
    console.log(`Current completedModuleCache set:`, Array.from(window.completedModuleCache));
    
    // Store all module responses
    const moduleResponses = {};
    
    for (let index = startIndex; index < totalModules; index++) {
      // Skip if module was already completed in this session
      const assistant = assistants[index];
      if (!assistant || !assistant.vanityName) {
        console.error('Invalid assistant at index', index, assistant);
        setLoading(false, `Error: Invalid assistant data at module ${index + 1}`);
        isProcessingAllModules = false;
        updateSubmitButtonState();
        return;
      }
      
      // Removed skipping already completed modules - process every module
      
      // Log the current state of our tracking sets
      console.log(`Current modulesInProgress set:`, Array.from(window.modulesInProgress));
      console.log(`Current completedModules set:`, Array.from(window.completedModuleCache));

      // Check if operation was cancelled
      if (isCancelled) {
        console.log('Operation cancelled by user');
        // Display cancellation message without replacing outputs from completed modules
        displayCancellationMessage(true);
        return;
      }

      if (creditsUsed >= totalCredits) {
        setLoading(false, 'Credit limit reached. Please purchase more credits to continue.');
        isProcessingAllModules = false;
        updateSubmitButtonState();
        return;
      }

      console.log(`Processing module ${index + 1}/${totalModules}: ${assistant.vanityName}`);
      try {
        // Display the blue processing message before processing each module
        setLoading(true, `Processing ${assistant.vanityName}...`);
        
        const response = await processSingleModule(content, assistant.vanityName, 3, 5000, true); // Skip display
        console.log(`Completed module ${index + 1}/${totalModules}: ${assistant.vanityName}`);
        window.completedModuleCache.add(assistant.vanityName);
        moduleResponses[assistant.vanityName] = response;
        
        // Display output without spinner during delay
        displayOutput(response);

        if (index < totalModules - 1) {
          // Calculate delay based on how many modules processed so far
          // Start with 5 seconds, increase by 1 second every 5 modules
          const baseDelay = 5000;
          const additionalDelay = Math.floor(index / 5) * 1000;
          const totalDelay = Math.min(baseDelay + additionalDelay, 30000); // Cap at 30 seconds
          
          console.log(`Waiting ${totalDelay/1000} seconds before processing next module...`);
          await new Promise(resolve => setTimeout(resolve, totalDelay));
        }
      } catch (err) {
        console.error(`Error processing module ${index + 1} (${assistant.vanityName}):`, err.message);
        
        // First check if this was a cancellation
        if (isCancelled || err.name === 'AbortError' || (err.message && err.message.includes('cancelled'))) {
          console.log('Operation was cancelled by user');
          displayCancellationMessage(true);
          return;
        }
        
        // Check for credit exhaustion (special case)
        if (err.creditsExhausted) {
          // Update credit UI with the latest from server
          fetchCredits();
          
          // Special error display for credit issues
          const normalContainer = document.getElementById('normal-response');
          normalContainer.innerHTML = `
            <div class="mb-4 relative overflow-hidden">
              <div class="bg-red-100 text-red-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
                Oops, there was an error.
                <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
              </div>
            </div>
            <div class="flex flex-col items-center mt-4">
              <p class="mb-3">You've used all your available credits.</p>
              <a href="/getstarted.html" class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded">Purchase Credits</a>
            </div>
          `;
          
          // Reset flags
          isProcessingAllModules = false;
          updateSubmitButtonState();
          return;
        }

        // For context window errors, we still need to stop batch processing
        if (err.message && err.message.includes('exceeds the context window')) {
          displayUnifiedError(assistant.vanityName, err, content, () => {
            // Retry callback - just retry this specific module
            processSingleModule(content, assistant.vanityName, 3);
          }, true);
          return;
        }
        
        // For all other errors, use the unified error handler with a custom retry callback
        displayUnifiedError(assistant.vanityName, err, content, () => {
          // Retry callback that continues with the remaining modules
          const retryAndContinue = async () => {
            try {
              // First retry the failed module
              const response = await processSingleModule(content, assistant.vanityName, 3);
              
              // If successful, store the response
              moduleResponses[assistant.vanityName] = response;
              window.completedModuleCache.add(assistant.vanityName);
              
              // Continue processing the remaining modules
              const remainingModules = assistants.slice(index + 1);
              if (remainingModules.length > 0) {
                // Reset processing flags
                isProcessingAllModules = true;
                
                // Continue with the next module, passing the next index
                processAllModules(content, index + 1);
              } else {
                // All modules completed
                let finalOutput = '<h3 class="text-lg font-bold mb-2">All Modules Results</h3>';
                let moduleCount = 0;
                
                for (const [moduleName, response] of Object.entries(moduleResponses)) {
                  moduleCount++;
                  finalOutput += `
                    <div class="mb-4">
                      <h4 class="font-bold">${sanitizeHTML(moduleName)}</h4>
                      <p>${sanitizeHTML(response)}</p>
                    </div>
                  `;
                }
                
                if (moduleCount === 0) {
                  finalOutput += '<p>No modules were successfully processed.</p>';
                }
                
                setLoading(false, finalOutput);
              }
            } catch (retryErr) {
              // If retry fails, display error again
              displayUnifiedError(assistant.vanityName, retryErr, content, retryAndContinue, true);
            }
          };
          
          // Execute the retry callback
          retryAndContinue();
        }, true, index);
        
        return;
      }
    }

    // Generate a combined output with all successful modules
    let finalOutput = '<h3 class="text-lg font-bold mb-2">All Modules Results</h3>';
    
    // Add each module's response to the output
    let moduleCount = 0;
    for (const [moduleName, response] of Object.entries(moduleResponses)) {
      moduleCount++;
      finalOutput += `
        <div class="mb-4">
          <h4 class="font-bold">${sanitizeHTML(moduleName)}</h4>
          <p>${sanitizeHTML(response)}</p>
        </div>
      `;
    }
    
    if (moduleCount === 0) {
      finalOutput += '<p>No modules were successfully processed.</p>';
    }
    
    setLoading(false, finalOutput);
    
    // Ensure streaming containers are hidden and success message is visible
    document.querySelectorAll('.streaming-container').forEach(el => el.classList.add('hidden'));
    const normalContainer = document.getElementById('normal-response');
    normalContainer.classList.remove('hidden');
    normalContainer.innerHTML = `
      <div class="mb-4 relative overflow-hidden">
        <div class="bg-yellow-100 text-yellow-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
          <span class="flex items-center justify-center">
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            Successfully Processed ${moduleCount} Modules!
          </span>
          <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
        </div>
      </div>
      ${finalOutput}
    `;
    
    isProcessingAllModules = false;
    window.isBatchProcessing = false; // Clear batch processing flag
    updateSubmitButtonState();
    console.log('All modules processed successfully');
  }

  async function processEmptyModules(content) {
    if (!token) {
      aiResponse.innerText = 'Error: Please sign in to process modules';
      window.location.href = '/getstarted.html';
      return;
    }
    
    // Get all modules and determine which ones haven't been generated yet
    const totalModules = assistants.length;
    isProcessingAllModules = true;
    isCancelled = false; // Reset cancellation state at the start
    currentAbortController = null; // Reset abort controller
    window.isBatchProcessing = true; // Set batch processing flag
    
    // Show Cancel button immediately for batch processing
    setLoading(true, 'Processing empty modules...');
    updateSubmitButtonState();
    
    // Get outputs from server to accurately determine empty modules
    const response = await fetch('/outputs', {
      headers: { 'Authorization': `Bearer ${token}` }
    });

    if (!response.ok) {
      console.error('Failed to fetch outputs:', response.status);
      setLoading(false, 'Error checking module status. Please try again.');
      isProcessingAllModules = false;
      updateSubmitButtonState();
      return;
    }

    const outputs = await response.json();
    const emptyModules = new Set();

    // Find which modules are empty or have placeholder content
    assistants.forEach(assistant => {
      const moduleOutputs = outputs.filter(
        output => output.assistantVanityName === assistant.vanityName ||
                 output.vanity_name === assistant.vanityName
      );
      
      // Module is empty if it has no outputs or only has "*****" outputs
      const isEmpty = moduleOutputs.length === 0 || 
        moduleOutputs.every(output => {
          // Check for {"text":"*****"} format
          try {
            const outputObj = typeof output.output === 'string' ? 
              JSON.parse(output.output) : output.output;
            return outputObj.text === "*****" || outputObj.text === "";
          } catch (e) {
            return output.output === "*****" || output.output === "";
          }
        });
      
      if (isEmpty) {
        emptyModules.add(assistant.vanityName);
      }
    });

    // Filter to only process empty modules
    const modulesToProcess = assistants.filter(assistant => 
      emptyModules.has(assistant.vanityName)
    );
    
    console.log(`Starting to process ${modulesToProcess.length} empty modules out of ${totalModules} total modules`);
    
    if (modulesToProcess.length === 0) {
      // Ensure streaming containers are hidden and show "no modules" message
      document.querySelectorAll('.streaming-container').forEach(el => el.classList.add('hidden'));
      const normalContainer = document.getElementById('normal-response');
      normalContainer.classList.remove('hidden');
      normalContainer.innerHTML = `
        <div class="mb-4 relative overflow-hidden">
          <div class="bg-yellow-100 text-yellow-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
            <span class="flex items-center justify-center">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              No Empty Modules to Process
            </span>
            <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
          </div>
        </div>
      `;
      isProcessingAllModules = false;
      updateSubmitButtonState();
      return;
    }
    
    // Store all module responses
    const moduleResponses = {};
    
    // Process each empty module
    for (let index = 0; index < modulesToProcess.length; index++) {
      // Check if operation was cancelled
      if (isCancelled) {
        console.log('Operation cancelled by user');
        setLoading(false, 'Operation cancelled by user');
        isProcessingAllModules = false;
        updateSubmitButtonState();
        return;
      }
      
      const assistant = modulesToProcess[index];
      if (!assistant || !assistant.vanityName) {
        console.error('Invalid assistant:', assistant);
        setLoading(false, `Error: Invalid assistant data at module ${index + 1}`);
        isProcessingAllModules = false;
        updateSubmitButtonState();
        return;
      }
      
      if (creditsUsed >= totalCredits) {
        setLoading(false, 'Credit limit reached. Please purchase more credits to continue.');
        isProcessingAllModules = false;
        updateSubmitButtonState();
        return;
      }

      console.log(`Processing empty module ${index + 1}/${modulesToProcess.length}: ${assistant.vanityName}`);
      
      // Log the current state of our tracking sets
      console.log(`Current modulesInProgress set:`, Array.from(window.modulesInProgress));
      console.log(`Current completedModules set:`, Array.from(window.completedModuleCache));

      try {
        // Display the blue processing message before processing each empty module
        setLoading(true, `Processing ${assistant.vanityName}...`);
        
        const response = await processSingleModule(content, assistant.vanityName, 3, 5000, true);
        console.log(`Completed empty module ${index + 1}/${modulesToProcess.length}: ${assistant.vanityName}`);
        moduleResponses[assistant.vanityName] = response;
        
        // Display output without spinner during delay
        displayEmptyOutput(response);
        
        // Add a delay between modules to avoid hitting rate limits
        if (index < modulesToProcess.length - 1) {
          // Calculate delay based on how many modules processed so far
          // Start with 5 seconds, increase by 1 second every 5 modules
          const baseDelay = 5000;
          const additionalDelay = Math.floor(index / 5) * 1000;
          const totalDelay = Math.min(baseDelay + additionalDelay, 30000); // Cap at 30 seconds
          
          console.log(`Waiting ${totalDelay/1000} seconds before processing next module...`);
          await new Promise(resolve => setTimeout(resolve, totalDelay));
        }
      } catch (err) {
        console.error(`Error processing empty module ${index + 1} (${assistant.vanityName}):`, err.message);
        
        // First check if this was a cancellation
        if (isCancelled || err.name === 'AbortError' || (err.message && err.message.includes('cancelled'))) {
          console.log('Operation was cancelled by user');
          setLoading(false, 'Operation cancelled by user');
          isProcessingAllModules = false;
          updateSubmitButtonState();
          return;
        }
        
        // Check for HTTP 429 errors - these need special handling with the unified error display
        if (err.message && err.message.includes('HTTP error! status: 429')) {
          displayUnifiedError(assistant.vanityName, err, content, () => {
            // Reset and retry just this module
            processSingleModule(content, assistant.vanityName, 3, 5000, true);
          });
          isProcessingAllModules = false;
          updateSubmitButtonState();
          return;
        }
        
        // Safely check message property
        const errorMessage = err.message || 'Unknown error';
        
        // Explicitly handle context window errors - never suppress them
        if (errorMessage.includes('exceeds the context window')) {
          setLoading(false, `<p>Error processing empty module ${index + 1} (${assistant.vanityName}): ${errorMessage}</p>`);
          isProcessingAllModules = false;
          updateSubmitButtonState();
          return;
        }
        
        const displayError = errorMessage.includes('timed out') && 
          (errorMessage.includes('500 Internal Server Error') || errorMessage.includes('OpenAI API'))
          ? 'OpenAI API timeout. The server was temporarily overloaded.'
          : errorMessage.includes('timed out') 
          ? 'Request timed out. Please try again later or contact support.'
          : errorMessage.includes('Too many requests')
          ? 'Too many requests. Please wait a moment and try again.'
          : errorMessage.includes('cancelled by user')
          ? 'Operation cancelled by user'
          : errorMessage.includes('exceeds the context window')
          ? errorMessage
          : err.creditsExhausted
          ? 'Insufficient credits. Please purchase more credits to continue.'
          : `Request failed: ${errorMessage}. Please try again.`;
        setLoading(false, `
          <p>Error processing empty module ${index + 1} (${assistant.vanityName}): ${sanitizeHTML(displayError)}</p>
          <button id="retry-empty-module-${index}" class="mt-2 bg-[#e50047] hover:bg-[#cc0040] text-white px-4 py-2 rounded">Retry ${assistant.vanityName}</button>
        `);
        isProcessingAllModules = false;
        updateSubmitButtonState();

        const attachRetryListener = () => {
          const retryButton = document.getElementById(`retry-empty-module-${index}`);
          if (retryButton) {
            retryButton.addEventListener('click', async () => {
              try {
                const response = await processSingleModule(content, assistant.vanityName, 3, 5000, true);
                // Store the response for this module
                moduleResponses[assistant.vanityName] = response;
              } catch (retryErr) {
                const retryDisplayError = retryErr.message.includes('timed out') && 
                  (retryErr.message.includes('500 Internal Server Error') || retryErr.message.includes('OpenAI API'))
                  ? 'OpenAI API timeout. The server was temporarily overloaded.'
                  : retryErr.message.includes('timed out') 
                  ? 'Request timed out. Please try again later or contact support.'
                  : retryErr.message.includes('Too many requests')
                  ? 'Too many requests. Please wait a moment and try again.'
                  : retryErr.message.includes('cancelled by user')
                  ? 'Operation cancelled by user'
                  : retryErr.message.includes('exceeds the context window')
                  ? retryErr.message
                  : retryErr.creditsExhausted
                  ? 'Insufficient credits. Please purchase more credits to continue.'
                  : `Request failed: ${retryErr.message}. Please try again.`;
                
                // For credits exhausted, update the UI and fetch the latest credit count from server
                if (retryErr.creditsExhausted) {
                  fetchCredits(); // Update the credit display with latest from server
                  // Show special message with link to purchase credits
                  setLoading(false, `
                    <p>You've used all your available credits.</p>
                    <a href="/getstarted.html" class="mt-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded inline-block">Purchase Credits</a>
                  `);
                  return;
                }
                
                setLoading(false, `Error processing empty module ${index + 1} (${assistant.vanityName}) on retry: ${sanitizeHTML(retryDisplayError)}`);
              }
            });
          } else {
            setTimeout(attachRetryListener, 100);
          }
        };
        attachRetryListener();
        return;
      }
    }

    // Generate a combined output with all successful modules
    let finalOutput = '<h3 class="text-lg font-bold mb-2">Empty Modules Results</h3>';
    
    // Add each module's response to the output
    let moduleCount = 0;
    for (const [moduleName, response] of Object.entries(moduleResponses)) {
      moduleCount++;
      finalOutput += `
        <div class="mb-4">
          <h4 class="font-bold">${sanitizeHTML(moduleName)}</h4>
          <p>${sanitizeHTML(response)}</p>
        </div>
      `;
    }
    
    if (moduleCount === 0) {
      finalOutput += '<p>No empty modules were successfully processed.</p>';
    }
    
    // Ensure streaming containers are hidden and success message is visible
    document.querySelectorAll('.streaming-container').forEach(el => el.classList.add('hidden'));
    const normalContainer = document.getElementById('normal-response');
    normalContainer.classList.remove('hidden');
    normalContainer.innerHTML = `
      <div class="mb-4 relative overflow-hidden">
        <div class="bg-yellow-100 text-yellow-800 font-semibold p-3 rounded-lg relative overflow-hidden text-center">
          <span class="flex items-center justify-center">
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            Successfully Processed ${moduleCount} Empty Modules!
          </span>
          <div class="absolute top-0 left-0 w-full h-full shine-effect"></div>
        </div>
      </div>
      ${finalOutput}
    `;
    
    isProcessingAllModules = false;
    window.isBatchProcessing = false; // Clear batch processing flag
    updateSubmitButtonState();
    console.log('All empty modules processed successfully');
  }

  document.getElementById('ai-button').addEventListener('click', async () => {
    // Check if this is a cancel action
    if (aiButton.getAttribute('data-action') === 'cancel') {
      console.log('User cancelled operation');
      
      // Prevent multiple cancellations
      if (isCancelling) {
        console.log('Cancellation already in progress');
        return;
      }
      
      isCancelled = true;
      
      // IMPORTANT: Immediately display cancellation message before any other operations
      // This ensures the blue/green message is replaced right away
      displayCancellationMessage(true);
      
      // Then abort any ongoing fetch request
      if (currentAbortController) {
        console.log('Aborting active fetch request');
        currentAbortController.abort('User cancelled');
        currentAbortController = null;
      }
      
      window.isBatchProcessing = false; // Reset batch processing flag
      isProcessingAllModules = false;
      updateSubmitButtonState();
      return;
    }
    
    if (!token) {
      aiResponse.innerText = 'Error: Please sign in to process modules';
      window.location.href = '/getstarted.html';
      return;
    }
    
    if (creditsUsed >= totalCredits) {
      setLoading(false, 'Credit limit reached. Please purchase more credits to continue.');
      return;
    }

    let textInput = editor.getHTML();
    // Check if there's actual text content, not just HTML tags
    if (!textInput || textInput === '<p></p>' || textInput === '<p><br></p>' || textInput.replace(/<[^>]*>/g, '').trim() === '') {
      textInput = '';
    }

    let fileContent = '';
    if (fileInput.files.length > 0) {
      setLoading(true, 'Converting file...');
      try {
        fileContent = await convertFileToText(fileInput.files[0]);
        console.log('Converted file to text:', fileContent.substring(0, 100) + '...');
      } catch (err) {
        console.error('File conversion error:', err);
        setLoading(false, `Error: ${err.message}`);
        return;
      }
    }

    const assistantVanityName = select.value;

    if (!textInput && !fileContent) {
      setLoading(false, 'Please provide some text or upload a file to process.');
      return;
    }

    // Reset cancellation state
    isCancelled = false;
    
    // Reset any existing abort controller
    if (currentAbortController) {
      currentAbortController = null;
    }
    
    // IMPORTANT: Clear module cache for this specific module to allow resubmission
    window.completedModuleCache.delete(assistantVanityName);
    window.modulesInProgress.delete(assistantVanityName);
    
    // Combine the content
    const combinedContent = textInput + (fileContent ? `\n\n[File Content]\n${fileContent}` : '');

    if (assistantVanityName === 'all-modules') {
      isProcessingAllModules = true;
      updateSubmitButtonState();
      await processAllModules(combinedContent);
    } else if (assistantVanityName === 'empty-modules') {
      isProcessingAllModules = true;
      updateSubmitButtonState();
      await processEmptyModules(combinedContent);
    } else {
      try {
        const response = await processSingleModule(combinedContent, assistantVanityName);
        // The response is already displayed by processSingleModule
      } catch (err) {
        const displayError = err.message.includes('timed out') 
          ? 'Request timed out. Please try again later or contact support.'
          : err.message.includes('Too many requests')
          ? 'Too many requests. Please wait a moment and try again.'
          : err.message.includes('cancelled by user')
          ? 'Operation cancelled by user'
          : err.message.includes('exceeds the context window')
          ? err.message
          : err.creditsExhausted // Check for our special credit error flag
          ? 'Insufficient credits. Please purchase more credits to continue.'
          : `Request failed: ${err.message}. Please try again.`;
        
        // For credits exhausted, update the UI and fetch the latest credit count from server
        if (err.creditsExhausted) {
          fetchCredits(); // Update the credit display with latest from server
          // Show special message with link to purchase credits
          setLoading(false, `
            <p>You've used all your available credits.</p>
            <a href="/getstarted.html" class="mt-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded inline-block">Purchase Credits</a>
          `);
          return;
        }
        
        setLoading(false, `<p>${assistantVanityName} failed to process.</p>
          <button id="retry-module-single" class="mt-2 bg-[#e50047] hover:bg-[#cc0040] text-white px-4 py-2 rounded">Try Again</button>
        `);

        const attachRetryListener = () => {
          const retryButton = document.getElementById('retry-module-single');
          if (retryButton) {
            retryButton.addEventListener('click', async () => {
              try {
                await processSingleModule(combinedContent, assistantVanityName);
                // The response is already displayed by processSingleModule
              } catch (retryErr) {
                const retryDisplayError = retryErr.message.includes('timed out') && 
                  (retryErr.message.includes('500 Internal Server Error') || retryErr.message.includes('OpenAI API'))
                  ? 'OpenAI API timeout. The server was temporarily overloaded.'
                  : retryErr.message.includes('timed out') 
                  ? 'Request timed out. Please try again later or contact support.'
                  : retryErr.message.includes('Too many requests')
                  ? 'Too many requests. Please wait a moment and try again.'
                  : retryErr.message.includes('cancelled by user')
                  ? 'Operation cancelled by user'
                  : retryErr.message.includes('exceeds the context window')
                  ? retryErr.message
                  : retryErr.creditsExhausted
                  ? 'Insufficient credits. Please purchase more credits to continue.'
                  : `Request failed: ${retryErr.message}. Please try again.`;
                
                // For credits exhausted, update the UI and fetch the latest credit count from server
                if (retryErr.creditsExhausted) {
                  fetchCredits(); // Update the credit display with latest from server
                  // Show special message with link to purchase credits
                  setLoading(false, `
                    <p>You've used all your available credits.</p>
                    <a href="/getstarted.html" class="mt-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded inline-block">Purchase Credits</a>
                  `);
                  return;
                }
                
                setLoading(false, `Error processing ${assistantVanityName} on retry: ${sanitizeHTML(retryDisplayError)}`);
              }
            });
          } else {
            setTimeout(attachRetryListener, 100);
          }
        };
        attachRetryListener();
      }
    }
  });

  document.getElementById('download-docx').addEventListener('click', () => {
    if (!token) {
      aiResponse.innerText = 'Error: Please sign in to download';
      window.location.href = '/getstarted.html';
      return;
    }
    
    // Get the page tag from the body data attribute
    const pageTag = document.body.dataset.pageTag || 'step1';
    
    setLoading(true, `Downloading ${pageTag} document...`);
    
    // Include pageTag in the request URL
    fetch(`/download?pageTag=${pageTag}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(errorData => {
            throw new Error(`${errorData.error || 'Failed to download DOCX'} (Status: ${res.status})`);
          });
        }
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        
        // Set filename explicitly based on pageTag
        if (pageTag === 'step2') {
          link.download = 'EIC Accelerator Step 2.docx';
        } else if (pageTag === 'business') {
          link.download = 'Business Plan.docx';
        } else if (pageTag === 'social') {
          link.download = 'Social Posts.docx';
        } else {
          link.download = 'EIC Accelerator Step 1 Proposal.docx';
        }
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch(err => {
        console.error('Download error:', err);
        setLoading(false);
        aiResponse.innerText = `Error: Failed to download DOCX - ${err.message}`;
      });
  });

  window.addEventListener('unload', () => {
    if (workerUrl) URL.revokeObjectURL(workerUrl);
  });

  // Add CSS for formatted content
  const styleEl = document.createElement('style');
  styleEl.textContent = `
    .formatted-content {
      white-space: pre-wrap;
      word-break: break-word;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji";
    }
    .formatted-content h1, .formatted-content h2, .formatted-content h3, 
    .formatted-content h4, .formatted-content h5, .formatted-content h6 {
      font-weight: bold;
      margin: 1em 0 0.5em 0;
    }
    .formatted-content h1 { font-size: 1.4em; }
    .formatted-content h2 { font-size: 1.3em; }
    .formatted-content h3 { font-size: 1.2em; }
    .formatted-content h4 { font-size: 1.1em; }
    .formatted-content ul, .formatted-content ol {
      margin-left: 1.5em;
      margin-bottom: 1em;
    }
    .formatted-content ul { list-style-type: disc; }
    .formatted-content ol { list-style-type: decimal; }
    .formatted-content p { margin-bottom: 0.5em; }
    .formatted-content code { 
      font-family: monospace;
      background-color: #f0f0f0; 
      padding: 0.1em 0.2em;
      border-radius: 3px;
    }
    .formatted-content pre {
      background-color: #f5f5f5;
      padding: 0.5em;
      border-radius: 4px;
      overflow-x: auto;
      margin: 0.5em 0;
    }
    .formatted-content blockquote {
      border-left: 3px solid #d1d1d1;
      padding-left: 1em;
      margin: 0.5em 0;
      color: #555;
    }
    .formatted-content a {
      color: #0066cc;
      text-decoration: underline;
    }
    .formatted-content table {
      border-collapse: collapse;
      margin: 0.5em 0;
    }
    .formatted-content table th, .formatted-content table td {
      border: 1px solid #ddd;
      padding: 0.3em 0.6em;
    }
  `;
  document.head.appendChild(styleEl);

  // Add custom CSS rule to ensure inputs are always hidden by default
  const style = document.createElement('style');
  style.textContent = '.history-input-container.hidden-input { display: none; }';
  document.head.appendChild(style);

  // Add emoji font styling to ensure proper rendering
  const emojiStyle = document.createElement('style');
  emojiStyle.textContent = `
    .formatted-content, .ai-response, .history-output {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji";
    }
    
    /* Increase emoji size slightly for better visibility */
    .formatted-content {
      font-size: 16px;
    }
  `;
  document.head.appendChild(emojiStyle);
  
  // Check if we should use the Responses API based on hostname
});

async function handleStreamingResponse(response) {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let accumulatedContent = '';
  
  try {
    // Show streaming container, hide normal container
    document.getElementById('streaming-response').classList.remove('hidden');
    document.getElementById('normal-response').classList.add('hidden');
    
    // Create streaming container once
    const streamingContainer = document.querySelector('.streaming-container') || 
      (() => {
        const container = document.createElement('div');
        container.className = 'streaming-container formatted-content';
        document.getElementById('streaming-response').innerHTML = ''; // Clear previous content
        document.getElementById('streaming-response').appendChild(container);
        return container;
      })();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const eventText = line.slice(6);
          if (eventText === '[DONE]') {
            continue;
          }
          
          try {
            const data = JSON.parse(eventText);
            
            if (data.type === 'error') {
              throw new Error(data.message || 'Stream error occurred');
            }
            
            if (data.content) {
              // Strip HTML tags and decode HTML entities
              const textContent = data.content
                .replace(/<[^>]*>/g, '') // Remove HTML tags
                .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
                .replace(/&lt;/g, '<') // Replace &lt; with <
                .replace(/&gt;/g, '>') // Replace &gt; with >
                .replace(/&amp;/g, '&') // Replace &amp; with &
                .replace(/&quot;/g, '"') // Replace &quot; with "
                .replace(/&#39;/g, "'") // Replace &#39; with '
                .replace(/\n/g, '<br>'); // Replace newlines with <br>
              
              accumulatedContent += textContent;
              // Update UI with formatted text content during streaming
              streamingContainer.innerHTML = accumulatedContent;
            }
          } catch (e) {
            console.error('Error parsing SSE event:', e);
          }
        }
      }
    }
    
    // Final update with sanitized content
    if (accumulatedContent) {
      const sanitizedContent = sanitizeHTML(accumulatedContent);
      streamingContainer.innerHTML = sanitizedContent;
      
      // Update credits and history after completion
      await fetchCredits();
      loadHistory();
    }
    
    // Reset button state after streaming is complete
    setLoading(false);
    
    return accumulatedContent;
  } catch (error) {
    console.error('Error processing stream:', error);
    throw error;
  } finally {
    reader.releaseLock();
  }
}

// Helper function to update output
function updateOutput(content) {
  const aiResponse = document.getElementById('aiResponse');
  if (aiResponse) {
    aiResponse.innerHTML = '';
    aiResponse.appendChild(content);
  }
}

async function sendRequest() {
  const input = document.getElementById('input').value.trim();
  if (!input) return;

  setLoading(true, 'Sending request...');
  
  try {
    const response = await fetch('/ask-ai-responses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token || getCookie('token')}`,
        'X-Session-Id': sessionId
      },
      body: JSON.stringify({
        text: input,
        assistantVanityName: currentModule.vanityName
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Check if this is a streaming response
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('text/event-stream')) {
      await handleStreamingResponse(response);
    } else {
      // Handle regular response
      const data = await response.json();
      const sanitizedContent = sanitizeHTML(data.response);
      updateOutput(sanitizedContent);
      setLoading(false);
      
      // Update credits and history
      await fetchCredits();
      loadHistory();
    }
  } catch (error) {
    console.error('Error:', error);
    updateOutput('Error: ' + error.message);
    setLoading(false);
  }
}

// Initialize the editor with TipTap
function initializeEditor() {
  const editor = new Editor({
    element: document.querySelector('#editor'),
    extensions: [StarterKit],
    content: '',
  });
  
  // Add any additional editor configuration here
  editor.on('update', () => {
    // Handle editor content updates
    const content = editor.getHTML();
    // You can process the content here if needed
  });
  
  return editor;
}